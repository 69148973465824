import { useEffect, useState, Dispatch, SetStateAction } from 'react';

// material-ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project imports
import CircularWithPath from '../../../components/@extended/progress/CircularWithPath';

// types
import { FormControlLabel, MenuItem, Radio, RadioGroup, Select, Stack, Tooltip, Typography } from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';
import { IconButton } from '@mui/material';
import { EndpointsType, LocationType, SelectCompanyType } from '../../../AllTypes';
import { OutlinedInput } from '@mui/material';
import { FormHelperText } from '@mui/material';
import { insert, update } from '../../../api/generalRoute';
import axiosServices from '../../../utils/axios';
import { CompanyEndpoints } from '../../../AllLables';
import { alertMessage } from '../../pages-helpers/AlertMessage';
import { UserProfile } from '../../../types/auth';
import useAuth from '../../../hooks/useAuth';

interface Props {
    isEditing: boolean;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
    editingData: LocationType;
    endpoints: EndpointsType;
    toggleRefreshData: () => void;
    closeModal: () => void;
}

export default function AddLocationModal({ isEditing, setIsEditing, editingData, endpoints, toggleRefreshData, closeModal }: Props) {

    const { user: currentUser } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [tempIsEditing, setTempIsEditing] = useState<boolean>(isEditing ? true : false);
    const [companies, setCompanies] = useState<SelectCompanyType[]>()

    useEffect(() => {
        axiosServices.get(CompanyEndpoints.getForSelect)
            .then(res => {
                setCompanies(res.data)
            })
        setLoading(false);
    }, []);

    useEffect(() => {
    
        // Set default company if user cannot modify it
        if (!hasMinLevel(currentUser, "Admin")) {
            let companyId = isEditing ? editingData?.companyId : currentUser?.companyId;
            setFieldValue('companyId', companyId);
        }
    }, [currentUser]);
    
    // TODO: move to helper class
    function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string) : Boolean {
        if (!user) {
        return false;
        }
        let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
        let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
        let ixTestLevel = orderedLevels.indexOf(userLevelName);

        return (ixLevel != -1 && ixTestLevel != -1 && ixLevel >= ixTestLevel);
    }

    const ItemSchema = Yup.object().shape({
        name: Yup.string().max(255).required('Name is required'),
        shortName: Yup.string().max(255).required('Short Name is required'),
        companyId: Yup.number().required('Select Company Name'),
        phone: Yup.string().matches(
            /^[0-9]{10}$/,
            "Phone no. must be of exactly 10 digits"
        ),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
        zip: Yup.string().required('Zip Code is required'),
        address: Yup.string().required('Address is required'),
    });

    const handleAlertClose = () => {
        setIsEditing(false)
        setTempIsEditing(false);
        toggleRefreshData();
        closeModal();
    };

    const locationInitialValues = {
        name: tempIsEditing && editingData ? editingData.name : '',
        shortName: tempIsEditing && editingData ? editingData.shortName : '',
        companyId: tempIsEditing && editingData ? editingData.companyId : '',
        phone: tempIsEditing && editingData ? editingData.phone : '',
        city: tempIsEditing && editingData ? editingData.city : '',
        state: tempIsEditing && editingData ? editingData.state : '',
        country: tempIsEditing && editingData ? editingData.country : '',
        zip: tempIsEditing && editingData ? editingData.zip : '',
        address: tempIsEditing && editingData ? editingData.address : '',
        address2: tempIsEditing && editingData ? editingData.address2 : '',
        isActive: tempIsEditing && editingData ? editingData.isActive : true,
    }

    const formik = useFormik({
        initialValues: locationInitialValues,
        validationSchema: ItemSchema,
        enableReinitialize: false,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (tempIsEditing && editingData) {
                    if (values.isActive === 'true') values.isActive = true;
                    if (values.isActive === 'false') values.isActive = false;

                    update(endpoints, editingData.id?.toString(), values)
                        .then(() => {
                            alertMessage(`Location updated successfully`, 'success');
                            handleAlertClose();
                        })
                        .catch(err => {
                            alertMessage('Something went wrong', 'error', err)
                            setSubmitting(false);
                        })
                }
                else {
                    insert(endpoints, values)
                        .then(() => {
                            alertMessage(`Location added successfully`, 'success');
                            handleAlertClose();
                            setSubmitting(false);
                        })
                        .catch(err => {
                            alertMessage('Something went wrong', 'error', err);
                            setSubmitting(false);
                        })
                }
            } catch (error) {
                alertMessage('Something went wrong', 'error', error);
            } finally {
                setSubmitting(false);
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    if (loading)
        return (
            <Box sx={{ p: 5 }}>
                <Stack direction="row" justifyContent="center">
                    <CircularWithPath />
                </Stack>
            </Box>
        );

    return (

        <Box>
            <FormikProvider value={formik}>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                <Form noValidate onSubmit={handleSubmit}>
                    <Stack sx={{ pr: 1 }} direction='row' justifyContent='space-between' alignItems='center'>
                        <DialogTitle><Typography fontSize='25px' fontWeight='bold'>{tempIsEditing ? 'Edit Location' : 'Add Location'}</Typography></DialogTitle>
                        <Tooltip title="Close">
                            <IconButton
                                color="inherit"
                                name='closeModal'
                                aria-label="close modal"
                                onClick={closeModal}
                                edge="start"
                            >
                                <CloseOutlined />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Divider />
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            {hasMinLevel(currentUser, "Admin") ?
                                                <>
                                                    <InputLabel htmlFor="companyId">Company</InputLabel>
                                                    <Select
                                                        id="companyId"
                                                        input={<OutlinedInput error={Boolean(touched.companyId && errors.companyId)} />}
                                                        {...getFieldProps('companyId')}
                                                    >
                                                        {!companies && <Box sx={{ p: 5 }}>
                                                            <Stack direction="row" justifyContent="center">
                                                                <CircularWithPath />
                                                            </Stack>
                                                        </Box>}
                                                        {companies && companies?.map((item, index) => (
                                                            <MenuItem key={index} value={parseInt(item.value)}>
                                                                {item.text}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {touched.companyId && errors.companyId && (
                                                        <FormHelperText error>
                                                            {errors.companyId}
                                                        </FormHelperText>
                                                    )}
                                                </>
                                            :
                                                <>
                                                    <InputLabel htmlFor="companyLabel">Company Name</InputLabel>
                                                    <OutlinedInput
                                                    fullWidth
                                                    id="companyLabel"
                                                    value={currentUser?.companyName}
                                                    name="companyLabel"
                                                    readOnly={true}
                                                    />
                                                </>
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="name">Name</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="name"
                                                placeholder="Enter Name"
                                                {...getFieldProps('name')}
                                                error={Boolean(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="shortName">Short Name</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="shortName"
                                                placeholder="Enter Short Name"
                                                {...getFieldProps('shortName')}
                                                error={Boolean(touched.shortName && errors.shortName)}
                                                helperText={touched.shortName && errors.shortName}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="phone">Phone</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="phone"
                                                placeholder="Enter Phone"
                                                {...getFieldProps('phone')}
                                                error={Boolean(touched.phone && errors.phone)}
                                                helperText={touched.phone && errors.phone}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="address">Address Line 1</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="address"
                                                multiline
                                                rows={2}
                                                placeholder="Enter Address"
                                                {...getFieldProps('address')}
                                                error={Boolean(touched.address && errors.address)}
                                                helperText={touched.address && errors.address}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="address2">Address Line 2</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="address2"
                                                multiline
                                                rows={2}
                                                placeholder="Enter Address (Optional)"
                                                {...getFieldProps('address2')}
                                                error={Boolean(touched.address2 && errors.address2)}
                                                helperText={touched.address2 && errors.address2}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="city">City</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="city"
                                                placeholder="Enter City"
                                                {...getFieldProps('city')}
                                                error={Boolean(touched.city && errors.city)}
                                                helperText={touched.city && errors.city}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="state">State</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="state"
                                                placeholder="Enter State"
                                                {...getFieldProps('state')}
                                                error={Boolean(touched.state && errors.state)}
                                                helperText={touched.state && errors.state}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="country">Country</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="country"
                                                placeholder="Enter Country"
                                                {...getFieldProps('country')}
                                                error={Boolean(touched.country && errors.country)}
                                                helperText={touched.country && errors.country}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="zip">Zip Code</InputLabel>
                                            <TextField
                                                fullWidth
                                                id="zip"
                                                placeholder="Enter Zip Code"
                                                {...getFieldProps('zip')}
                                                error={Boolean(touched.zip && errors.zip)}
                                                helperText={touched.zip && errors.zip}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={3}>
                                            <Stack direction='row' alignItems='center'>
                                                <InputLabel>Status :</InputLabel>
                                                <RadioGroup {...getFieldProps('isActive')} aria-label="type" name="isActive" row>
                                                    <Box sx={{ ml: 1 }}>
                                                        <FormControlLabel value="true" control={<Radio />} label="Active" />
                                                        <FormControlLabel value="false" control={<Radio />} label="Inactive" />
                                                    </Box>
                                                </RadioGroup>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Stack
                                        direction='row'
                                        spacing={1}
                                        alignItems='center'
                                        justifyContent='flex-end'
                                        sx={{ pt: 1 }}
                                        >
                                            <Button color="secondary" onClick={closeModal}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" variant="contained" disabled={isSubmitting}>
                                                Save
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {/* <Divider /> */}
                    {/* <DialogActions sx={{ p: 2.5 }}> */}
                    {/* <Grid item> */}
                    {/* <Stack direction="row"> */}

                    {/* </Stack> */}
                    {/* </Grid> */}
                    {/* </DialogActions> */}
                </Form>
                {/* </LocalizationProvider> */}
            </FormikProvider>
        </Box>
    );
}
