import { format, FormatOptions } from "date-fns";

// TODO: Migrate to dayjs
export function formatDate(date: string | number | Date, formatStr: string = "MM/dd/yyyy", options?: FormatOptions) {
  try {
    return format(date, formatStr);
  } catch (error) {
    console.error(`Cannot format date '${date}'`);
    console.error(error);
    return "";
  }
}
