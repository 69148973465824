import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Stack, TableContainer, TableHead, TableRow, TableFooter, TableCell, TableBody, Box, Table, Button, Typography } from "@mui/material";
import {
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  HeaderGroup,
  flexRender,
  Row,
} from "@tanstack/react-table";
import { Divider } from "antd";
import { Fragment } from "react/jsx-runtime";
import MainCard from "../../components/MainCard";
import ScrollX from "../../components/ScrollX";
import { SelectColumnSorting, HeaderSort, TablePagination, RowSelection } from "../../components/third-party/react-table";
import "./styles.css";

interface ReactTableProps {
  showPagination?: boolean;
  search?: string;
  defaultSorting?: [
    {
      id: string;
      desc: boolean;
    }
  ];
  showFooter?: boolean;
  columns: any;
  data: any;
  modalToggler?: () => void;
  showAddBtn: boolean;
  getRowStyle?: (row: Row<any>) => {};
}

export default function DisplayTable({
  showPagination = true,
  showFooter = false,
  search,
  defaultSorting,
  columns,
  data,
  modalToggler = () => {},
  showAddBtn,
  getRowStyle = (row: Row<any>) => ({}),
}: ReactTableProps) {
  const [sorting, setSorting] = useState<SortingState>(
    defaultSorting || [
      {
        id: "name",
        desc: false,
      },
    ]
  );
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    setGlobalFilter(search || "");
  }, [search]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      globalFilter,
      columnVisibility: {
        id: false,
      },
    },
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    getRowCanExpand: () => true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <MainCard content={false}>
      <Box>
        {!data && <Typography>Loading</Typography>}
        {/* <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          alignItems="right"
          justifyContent="end"
          sx={{ padding: 2, ...(downSM && { '& .MuiOutlinedInput-root, & .MuiFormControl-root': { width: '100%' } }) }}
        >
       
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" sx={{ width: { xs: '100%', sm: 'auto' } }}>
            <SelectColumnSorting {...{ getState: table.getState, getAllColumns: table.getAllColumns, setSorting }} />
            <Stack direction="row" spacing={2} alignItems="center">
              {showAddBtn && <Button variant="contained" startIcon={<PlusOutlined />} onClick={modalToggler} >
                Add {from}
              </Button>}
            </Stack>
          </Stack>
        </Stack> */}
        <ScrollX>
          <Stack>
            <RowSelection selected={Object.keys(rowSelection).length} />
            <TableContainer>
              <Table>
                <TableHead>
                  {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        if (header.column.columnDef.meta !== undefined && header.column.getCanSort()) {
                          Object.assign(header.column.columnDef.meta, {
                            className: header.column.columnDef.meta.className + " cursor-pointer prevent-select",
                          });
                        }
                        return (
                          <TableCell
                            key={header.id}
                            {...header.column.columnDef.meta}
                            onClick={header.column.getToggleSortingHandler()}
                            {...(header.column.getCanSort() &&
                              header.column.columnDef.meta === undefined && {
                                className: "cursor-pointer prevent-select",
                              })}
                          >
                            {header.isPlaceholder ? null : (
                              <Stack direction="row" spacing={1} alignItems="center">
                                <Box>{flexRender(header.column.columnDef.header, header.getContext())}</Box>
                                {header.column.getCanSort() && <HeaderSort column={header.column} />}
                              </Stack>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHead>
                {table.getRowModel().rows && table.getRowModel().rows.length <= 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }} colSpan={columns.length}>
                        <Typography sx={{ width: "100%", color: "grey.500" }} component="pre">
                          No data available.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {table.getRowModel().rows.map((row) => (
                      <Fragment key={row.id}>
                        <TableRow sx={getRowStyle(row)}>
                          {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                          ))}
                        </TableRow>
                      </Fragment>
                    ))}
                  </TableBody>
                )}
                {showFooter && (
                  <TableFooter>
                    {table.getFooterGroups().map((footerGroup) => (
                      <TableRow key={footerGroup.id}>
                        {footerGroup.headers.map((header) => (
                          <TableCell key={header.id}>
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
            {showPagination && (
              <>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <TablePagination
                    {...{
                      setPageSize: table.setPageSize,
                      setPageIndex: table.setPageIndex,
                      getState: table.getState,
                      getPageCount: table.getPageCount,
                    }}
                  />
                </Box>
              </>
            )}
          </Stack>
        </ScrollX>
      </Box>
    </MainCard>
  );
}
