import { useEffect, useState, Dispatch, SetStateAction } from "react";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Form, Formik } from "formik";

// project imports
import CircularWithPath from "../../../components/@extended/progress/CircularWithPath";

// types
import { FormControlLabel, FormHelperText, OutlinedInput, InputAdornment, Stack, Tooltip, Typography, Switch } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { EndpointsType, InkType } from "../../../AllTypes";
import { insert, update } from "../../../api/generalRoute";
import { alertMessage } from "../../pages-helpers/AlertMessage";

interface Props {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  editingData: InkType;
  endpoints: EndpointsType;
  toggleRefreshData: () => void;
  closeModal: () => void;
}

export default function AddInkModal({ isEditing, setIsEditing, editingData, endpoints, toggleRefreshData, closeModal }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [tempIsEditing, setTempIsEditing] = useState<boolean>(isEditing ? true : false);

  useEffect(() => {
    setLoading(false);
  }, []);

  const ItemSchema = Yup.object().shape({
    name: Yup.string().max(255).required("Product Code is required"),
    density: Yup.number().positive("Density must be a positive number.").required("Density is required"),
  });

  const handleAlertClose = () => {
    setIsEditing(false);
    setTempIsEditing(false);
    toggleRefreshData();
    closeModal();
  };

  const InkInitialValues = {
    name: tempIsEditing && editingData ? editingData.name : "",
    description: tempIsEditing && editingData ? editingData.description : "",
    density: tempIsEditing && editingData ? editingData.density : 0,
    isActive: tempIsEditing && editingData ? editingData.isActive : true,
  };

  // const formik = useFormik({
  //     initialValues: InkInitialValues,
  //     validationSchema: ItemSchema,
  //     enableReinitialize: false,
  //     onSubmit: async (values: InkType, { setSubmitting }) => {
  //         try {
  //             values.isActive = (values.isActive === 'true'||values.isActive === true) ? true : false;
  //             if (tempIsEditing && editingData) {
  //                 update(endpoints, editingData.id?.toString(), values)
  //                     .then(() => {
  //                         alertMessage("Component updated successfully", "success");
  //                         handleAlertClose();
  //                     }).catch((err) => {
  //                         alertMessage(err?.title, 'error');
  //                         setSubmitting(false);
  //                     });
  //             }
  //             else {
  //                 insert(endpoints, values).then(() => {
  //                     alertMessage("Component added successfully", "success");
  //                     handleAlertClose();
  //                     setSubmitting(false);
  //                 }).catch(err => {
  //                     alertMessage(err, 'error');
  //                     setSubmitting(false);
  //                 })
  //             }

  //         } catch (error) {
  //             alertMessage(`Something went wrong`, 'error')
  //         } finally {
  //             setSubmitting(false);
  //             setIsEditing(false);
  //             setTempIsEditing(false);
  //         }
  //     }
  // });

  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <Formik
      initialValues={InkInitialValues}
      validationSchema={ItemSchema}
      enableReinitialize={false}
      onSubmit={async (values: InkType, { setSubmitting }) => {
        try {
          // values.isActive = (values.isActive === 'true'||values.isActive === true) ? true : false;
          if (tempIsEditing && editingData) {
            update(endpoints, editingData.id?.toString(), values)
              .then(() => {
                alertMessage("Component updated successfully", "success");
                handleAlertClose();
              })
              .catch((err) => {
                alertMessage(err?.title, "error");
                setSubmitting(false);
              });
          } else {
            insert(endpoints, values)
              .then(() => {
                alertMessage("Component added successfully", "success");
                handleAlertClose();
                setSubmitting(false);
              })
              .catch((err) => {
                alertMessage("Something went wrong", "error", err);
                setSubmitting(false);
              });
          }
        } catch (error) {
          alertMessage(`Something went wrong`, "error");
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting, getFieldProps, values }) => (
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {tempIsEditing ? "Edit Component" : "Add Component"}
              </Typography>
            </DialogTitle>
            <Tooltip title="Close">
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent sx={{ width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Product Code</InputLabel>
                      <TextField
                        fullWidth
                        id="name"
                        placeholder="Product Code"
                        {...getFieldProps("name")}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="density">Density</InputLabel>
                      <OutlinedInput
                        fullWidth
                        id="density"
                        placeholder="Density"
                        {...getFieldProps("density")}
                        type="number"
                        endAdornment={<InputAdornment position="end">lb/gal</InputAdornment>}
                        error={Boolean(touched.density && errors.density)}
                        // helperText={touched.density && errors.density}
                      />
                      {touched?.density && <FormHelperText error>{errors.density}</FormHelperText>}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="description">Description</InputLabel>
                      <TextField
                        fullWidth
                        id="description"
                        placeholder="Description"
                        {...getFieldProps("description")}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" alignItems="center" spacing={3}>
                      <FormControlLabel control={<Switch {...getFieldProps("isActive")} checked={values.isActive} />} label="Active" />
                      {/* <InputLabel>Status :</InputLabel>
                                            <RadioGroup {...getFieldProps('isActive')} aria-label="type" name="isActive" row>
                                                <Box>
                                                    <FormControlLabel value='true' control={<Radio />} label="Active" />
                                                    <FormControlLabel value='false' control={<Radio />} label="Inactive" />
                                                </Box>
                                            </RadioGroup> */}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {/* <Divider /> */}
          <DialogActions sx={{ p: 2.5 }}>
            {/* <Grid item> */}
            {/* <Stack direction="row"> */}
            <Button color="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              Save
            </Button>
            {/* </Stack> */}
            {/* </Grid> */}
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
