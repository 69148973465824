// material-ui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// project import
import { PopupTransition } from "../../components/@extended/Transitions";

// assets
import DeleteFilled from "@ant-design/icons/DeleteFilled";

// types
import { EndpointsType } from "../../AllTypes";
import { deleteById } from "../../api/generalRoute";
import Avatar from "../../components/@extended/Avatar";
import { alertMessage } from "./AlertMessage";

interface Props {
  endpoints: EndpointsType;
  deleteId: string | undefined;
  deleteName: string;
  title: string;
  open: boolean;
  handleClose: () => void;
}

// ==============================|| ITEM - DELETE ||============================== //

export default function AlertItemDelete({ endpoints, deleteId, deleteName, title, open, handleClose }: Props) {
  const deletehandler = async () => {
    try {
      await deleteById(endpoints, deleteId).then(() => {
        alertMessage(`${title} deleted successfully.`, "success");
      });
    } catch (error) {
      alertMessage(`Something went wrong`, "error");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color="error" sx={{ width: 72, height: 72, fontSize: "1.75rem" }}>
            <DeleteFilled />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              Delete Alert
            </Typography>
            <Typography align="center">
              Are you sure you want to delete
              <Typography variant="subtitle1" component="span">
                {" "}
                &quot;{deleteName}&quot; ?
              </Typography>
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={handleClose} color="secondary" variant="outlined">
              Cancel
            </Button>
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={() => {
                deletehandler();
                handleClose();
              }}
              autoFocus
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
