// import {  useState} from "react";
import { Box, Button, DialogContent, DialogTitle, Divider, Grid, InputLabel, Stack, TextField, Tooltip, Typography } from "@mui/material";

import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { useState } from "react";
import axiosServices from "../../../utils/axios";
import { alertMessage } from "../../pages-helpers/AlertMessage";
import { Form, Formik } from "formik";

import * as Yup from "yup";

interface Props {
  closeModal: () => void;
  formulaId: number;
  weight: number;
}

export default function ShareFormulaModal({ closeModal, formulaId, weight }: Props) {
  const schema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i, "Email Invalid")
      .required("Email is required")
      .max(40),
  });

  return (
    <Box sx={{ maxHeight: "90vh", width: "450px", maxWidth: "90vw" }} mx={{ width: "40vw" }}>
      <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h4" fontWeight="bold" component="span">
            Share Formula
          </Typography>
        </DialogTitle>

        <Tooltip title="Close">
          <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
            <CloseOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            axiosServices
              .post(`/api/formula/${formulaId}/shareByEmail?weight=${weight}&email=${values.email}`)
              .then(() => {
                alertMessage(`Email sent`, "success");
                closeModal();
              })
              .catch((err) => {
                alertMessage("Something went wrong", "error", err);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ touched, errors, getFieldProps, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-login">Email Address</InputLabel>
                    <TextField
                      id="email-login"
                      type="email"
                      {...getFieldProps("email")}
                      placeholder="Enter email address"
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>

                  <Stack direction="row" spacing={2} sx={{ width: 1, p: 4 }}>
                    <Button fullWidth onClick={closeModal} color="secondary" variant="outlined">
                      Cancel
                    </Button>
                    <Button type="submit" fullWidth color="primary" variant="contained" disabled={isSubmitting} autoFocus>
                      Share
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      {/* <DialogActions sx={{ p: 2.5 }}></DialogActions> */}
    </Box>
  );
}
