// import {  useState} from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Switch, Tooltip, Typography } from "@mui/material";

import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { FormulaComponentItem } from "../../../types/formulation";
import React, { useState } from "react";
import { GetFormulaType, FormulaCreationType } from "../../../AllTypes";
import axiosServices from "../../../utils/axios";
import { alertMessage } from "../../pages-helpers/AlertMessage";
import useAuth from "../../../hooks/useAuth";

import { SelectSeriesType } from "../../../AllTypes";

interface Props {
  closeModal: () => void;
  data: FormulaComponentItem[];
  series: SelectSeriesType;
  formula: GetFormulaType | null;
  weight: number;
  // formulasBySeries:CustomFormulaType
}

export default function ConfirmDeductFromInventory({
  closeModal,
  data,
  series,
  formula,
  weight,
}: // formulasBySeries
Props) {
  const { user: currentUser } = useAuth();

  const deductFormula = async () => {
    try {
      if (!formula) {
        alertMessage("No Formula Selected", "error");
        return;
      }
      const formulaCreation: FormulaCreationType = {
        formulaId: formula.id as number,
        weightInGrams: weight,
        components: data.map((d) => ({
          componentId: d.componentId,
          formulaId: d.componentId ? undefined : d.formulaId,
          weightInGrams: d.amountInGrams,
        })),
      };

      if (!currentUser?.locationId) {
        alertMessage("Cannot deduct inventory without a location.", "warning");
        return;
      }
      await axiosServices.post(`/api/formula/deduct?locationId=${currentUser.locationId}`, formulaCreation);

      alertMessage("Inventory successfully deducted.", "info");
      closeModal();
    } catch (err) {
      alertMessage("Something went wrong ", "error", err);
    }
  };

  return (
    <Box sx={{ maxHeight: "90vh", width: "450px", maxWidth: "90vw" }} mx={{ width: "40vw" }}>
      <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h4" fontWeight="bold" component="span">
            Confirm Inventory Deduction
          </Typography>
        </DialogTitle>

        <Tooltip title="Close">
          <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
            <CloseOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={3} sx={{ pb: 0 }}>
              <span>
                Are you sure you want to deduct the components for <b>{weight}g</b> of formula <b>{formula?.name}</b>?
              </span>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ width: 1, p: 4 }}>
              <Button fullWidth onClick={closeModal} color="secondary" variant="outlined">
                Cancel
              </Button>
              <Button fullWidth color="primary" variant="contained" onClick={deductFormula} autoFocus>
                Deduct Components
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions sx={{ p: 2.5 }}></DialogActions> */}
    </Box>
  );
}
