import React from "react";
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Stack,
  Tooltip,
  Typography,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton,
  Fade,
  FormControl,
  FormControlLabel,
  Popper,
  Radio,
  RadioGroup,
  Checkbox,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useFormik, Form, FormikProvider, FieldArray, FieldArrayRenderProps } from "formik";
import * as Yup from "yup";
import CircularWithPath from "../../../components/@extended/progress/CircularWithPath";
import { CloseOutlined, DeleteOutlined, PlusOutlined, ColumnHeightOutlined } from "@ant-design/icons";

import FormulaChip from "../../../components/FormulaChip";

import { EndpointsType, GetFormulaType, SelectInkType } from "../../../AllTypes";
import { ColorResult, SketchPicker } from "react-color";
import axiosServices from "../../../utils/axios";
import { InkEndpoints } from "../../../AllLables";
import { insert, update } from "../../../api/generalRoute";
import { alertMessage } from "../../pages-helpers/AlertMessage";
import { FormulaComponentItem } from "../../../types/formulation";
import { Autocomplete } from "@mui/material";
import { RoleType } from "../../../utils/utility";
import { UserTypeRedux } from "../../../types/user";
import { useAppSelector } from "../../../redux/hook";

interface Props {
  endpoints: EndpointsType;
  toggleRefreshData: () => void;
  closeModal: (updatedId?: number) => void;
  open: boolean;
  seriesId?: number;
  formulaId: number | null;
  cloneFormula: boolean;
}

interface CustomColorProps extends ColorResult {
  hsv: {
    h: number;
    s: number;
    v: number;
    a?: number | undefined;
  };
  source: "";
  oldHue: number;
  hex: string;
}

export default function AddFormulaModal({ endpoints, toggleRefreshData, closeModal, open, seriesId, formulaId, cloneFormula }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState<boolean>(true);
  // TODO: initialize on editino
  const [color, setColor] = useState<CustomColorProps>({
    rgb: { r: 0, g: 0, b: 0, a: 0 },
    hsl: { h: 0, s: 0, l: 0, a: 0 },
    hsv: { h: 0, s: 0, v: 0, a: 0 },
    hex: "#eee",
    source: "",
    oldHue: 0,
  });
  const [inks, setInks] = useState<SelectInkType[]>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openColorPopper, setOpenColorPopper] = useState<boolean>(false);
  const [displayIndividualAmountPercentage, setDisplayIndividualAmountPercentage] = useState<number[]>([0]);
  const [displayTotalGrams, setDisplayTotalGrams] = useState<number>(0);
  const [displayTotalPercentage, setDisplayTotalPercentage] = useState<number>(0);
  const [calType, setcalType] = useState<string>("gr");
  const user: UserTypeRedux = useAppSelector(({ user }) => user.userinfo as UserTypeRedux);

  let initialValues = {
    id: 0,
    name: "",
    description: "",
    rgbColors: "",
    versionNumber: 0,
    companyId: 0,
    companyName: "",
    createdBy: "",
    locationId: 0,
    locationName: "",
    shareAcrossCompany: true,
    seriesId: 0,
    masterFormula: user?.userLevelName === RoleType.SUPER_ADMIN || user?.userLevelName === RoleType.ADMIN,
    components: [
      {
        id: 0,
        formulaId: 0,
        componentId: null,
        amount: 0,
        amountInGrams: 0,
        isActive: true,
        isDeleted: false,
        createdBy: "",
      },
    ],
  };
  if (!initialValues.masterFormula) {
    initialValues.companyName = user?.companyName;
    initialValues.locationName = user?.locationName;
  }
  const [formula, setFormula] = useState<GetFormulaType>(initialValues);

  const ItemSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    rgbColors: Yup.string().required("Pick a color"),
    components: Yup.array().of(
      Yup.object().shape({
        componentId: Yup.string().required("Component ID is required"),
        amount: Yup.number().required("Amount is required").min(0, "Amount must be greater than zero"),
      })
    ),
  });

  const handleAlertClose = (updatedId: number) => {
    toggleRefreshData();
    closeModal(updatedId);
  };

  // TODO: change company and location validations and presets to BE as it is not being displayed ??

  function round(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  // TODO: refactor
  function calculateAmount() {
    console.log("calculateAmount");
    const data = document.querySelectorAll<HTMLInputElement>(calType === "gr" ? `[id^='amount-']` : `[id^='Percentage-']`);
    const length = data.length;
    let total = 0;
    let totalPercentage = 0;

    // Calculate total amount
    for (let i = 0; i < length; i++) {
      total += parseFloat(data[i].value) || 0;
    }
    setDisplayTotalGrams(total);

    const amounts = new Array<number>(length);
    // Calculate individual percentages
    for (let i = 0; i < length; i++) {
      var value = +data[i].value;
      if (calType === "gr") {
        amounts[i] = (value / total) * 100 || 0;
      } else {
        amounts[i] = value;
      }
    }
    setDisplayIndividualAmountPercentage(amounts);

    // Calculate total percentage
    for (let i = 0; i < amounts.length; i++) {
      totalPercentage += amounts[i] || 0;
    }
    setDisplayTotalPercentage(totalPercentage);
  }

  function handleDeleteInkModule(_arrayHelpers: FieldArrayRenderProps, index: number) {
    for (let i = index; i < values.components.length - 1; i++) {
      values.components[i] = { ...values.components[i + 1] };
    }
    values.components.pop();
    setTimeout(() => {
      calculateAmount();
    }, 200);
  }

  useEffect(() => {
    if (!formulaId) {
      setLoading(false);
      return;
    }

    setLoading(true);
    axiosServices
      .get(`/api/formula/${formulaId}`)
      .then((res) => {
        let formulaData = res.data as GetFormulaType;

        if (cloneFormula) {
          formulaData.id = 0;
          formulaData.name += " - Copy";
          formulaData.description = "";
          formulaData.dateCreated = undefined;
          formulaData.createdBy = undefined;

          // TODO: change to permission handler (if it is not admin)
          if (user?.userLevelName !== RoleType.SUPER_ADMIN && user?.userLevelName !== RoleType.ADMIN) {
            formulaData.masterFormula = false;
            formulaData.companyId = user?.companyId;
            formulaData.companyName = user?.companyName;
            formulaData.locationId = user?.locationId;
            formulaData.locationName = user?.locationName;
          } else {
            formulaData.masterFormula = true;
            formulaData.companyId = undefined;
            formulaData.companyName = "";
            formulaData.locationId = undefined;
            formulaData.locationName = "";
          }

          formulaData?.components?.forEach((c) => {
            c.id = 0;
            c.formulaId = 0;
          });
        }

        formik.setValues(formulaData);
        setFormula(formulaData);
        // setIsShareAcross(formulaData.shareAcrossCompany);
        setColor((c) => ({
          ...c,
          hex: formulaData.rgbColors,
        }));

        setTimeout(() => {
          calculateAmount();
        }, 200);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [open, formulaId]);

  useEffect(() => {
    axiosServices
      .get(InkEndpoints.getForSelect)
      .then((res) => {
        setInks(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  function setDeletedFlags() {
    const tempCompo = JSON.parse(JSON.stringify(formula?.components));
    for (let i = 0; i < tempCompo.length; i++) {
      const componentExists: boolean = values.components.some((cmp) => {
        return cmp.id === tempCompo[i].id;
      });
      if (!componentExists) {
        tempCompo[i].isDeleted = true;
        tempCompo[i].isActive = false;
        values.components.push(tempCompo[i]);
      }
    }
  }

  const formik = useFormik({
    initialValues: formula,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      for (let i = 0; i < values.components?.length; i++) {
        values.components[i].amount = displayIndividualAmountPercentage[i];
      }

      values.seriesId = seriesId;

      try {
        if (formula.id) {
          if (round(displayTotalPercentage) !== 100) {
            alertMessage(`Components don't add up to 100%`, "warning");
            setSubmitting(false);
            return;
          }
          //  Components don't add up to 100%

          setDeletedFlags();
          update(endpoints, formula.id?.toString(), values)
            .then((res) => {
              alertMessage(`Formula updated successfully`, "success");
              handleAlertClose(res?.data?.id as number);
              // updateSelectedInk?.();
            })
            .catch((err) => {
              alertMessage("Something went wrong", "error", err);
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          if (round(displayTotalPercentage) !== 100) {
            alertMessage(`Components don't add up to 100%`, "warning");
            return;
          }
          insert(endpoints, values)
            .then((res) => {
              alertMessage(`Formula added successfully`, "success");
              handleAlertClose(res?.data?.id as number);
              // updateFormula?.()
            })
            .catch((err) => {
              alertMessage("Something went wrong", "error", err);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      } catch (error) {
        alertMessage(`Something went wrong`, "error", error);
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, handleChange } = formik;

  function isErrorInComponentId(index: number) {
    if (values.components[index].componentId === null) {
      return true;
    } else return false;
  }

  function isErrorInAmountInGrams(index: number) {
    if (values.components[index].amountInGrams === 0) return true;
    else return false;
  }
  function isErrorInAmount(index: number) {
    if (values.components[index].amount === 0) return true;
    else return false;
  }

  const handleChangeColorInputType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setcalType(e.target.value);
  };

  const handleClickCalculateRemainingPer = (cmp: FormulaComponentItem, indexno: number) => {
    let sum = 0;
    sum = values.components
      .map((x) => x.amount)
      .reduce((acc, cur) => {
        if (acc !== undefined && cur !== undefined) {
          return (acc || 0) + (cur || 0);
        }
      }, 0) as number;

    let newAmount = round(cmp.amount + 100 - sum);
    if (newAmount < 0) {
      newAmount = 0;
    }
    cmp.amount = newAmount;

    setTimeout(() => {
      calculateAmount();
    }, 200);
  };

  // const handleChange

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <Box
      sx={(theme) => ({
        maxHeight: "89vh",
        width: "85vw",
        [theme.breakpoints.only("xs")]: {
          maxHeight: "100vh",
          width: "100vw",
        },
      })}
    >
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {!formulaId && "Add Formula"}
                {formulaId && !cloneFormula && "Edit Formula"}
                {formulaId && cloneFormula && "Clone Formula"}
              </Typography>
            </DialogTitle>
            <Tooltip title="Close">
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={() => closeModal()} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} sx={{ pt: "0 !important" }}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <span>
                        <FormulaChip
                          masterFormula={values.masterFormula}
                          shareAcrossCompany={values.shareAcrossCompany}
                          longText
                          filled
                        ></FormulaChip>
                      </span>
                      {!values?.masterFormula && (
                        <>
                          <Divider orientation="vertical" flexItem></Divider>
                          <Stack direction="column" spacing={0}>
                            <Stack direction="row" spacing={1}>
                              <InputLabel>Company:&nbsp;</InputLabel>
                              {formula.companyName}
                            </Stack>
                            {!values?.masterFormula && !values?.shareAcrossCompany && (
                              <Stack direction="row" spacing={1}>
                                <InputLabel>Location:&nbsp;</InputLabel>
                                {formula.locationName || user?.locationName}
                              </Stack>
                            )}
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item xs>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="name">Name </InputLabel>
                        <TextField
                          fullWidth
                          id="name"
                          placeholder="Name"
                          {...getFieldProps("name")}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                        {/* {color.hex}
                        -
                        {values.rgbColors} */}
                        <Popper sx={{ zIndex: 9999 }} open={openColorPopper} placement="bottom" anchorEl={anchorEl} transition>
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <Box
                                sx={{
                                  bgcolor: "white",
                                }}
                              >
                                <Stack direction="column">
                                  <SketchPicker
                                    // onAccept={() => {
                                    //   setFieldValue("rgbColors", color.hex as string);
                                    //   setOpenColorPopper(false);
                                    // }}
                                    color={color.hex}
                                    onChangeComplete={(e) => {
                                      setColor(e as CustomColorProps);
                                    }}
                                    // onCancel={() => setOpenColorPopper(false)}
                                  />
                                </Stack>
                                <Paper
                                  sx={{
                                    bgcolor: "white",
                                    p: 1,
                                  }}
                                >
                                  <Stack direction="row" spacing={1}>
                                    <Button fullWidth color="primary" onClick={() => setOpenColorPopper(false)}>
                                      Cancel
                                    </Button>
                                    <Button
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        setFieldValue("rgbColors", color.hex as string);
                                        setOpenColorPopper(false);
                                      }}
                                    >
                                      OK
                                    </Button>
                                  </Stack>
                                </Paper>
                              </Box>
                            </Fade>
                          )}
                        </Popper>
                        <Stack direction="column" alignItems="center">
                          <Box
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                              setOpenColorPopper(!openColorPopper);
                            }}
                            sx={{
                              boxShadow: "0px 1px 5px #000000AA",
                              backgroundColor: `${values.rgbColors}`,
                              height: "68px",
                              width: "110px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              textAlign: "center",
                              padding: "13px 3px",
                            }}
                          >
                            <Stack justifyContent="center" alignItems="stretch">
                              <Typography variant="body2" sx={{ color: "#222222" }}>
                                Click to choose color
                              </Typography>
                            </Stack>
                          </Box>
                          {touched?.rgbColors && errors?.rgbColors && <FormHelperText error> {errors?.rgbColors} </FormHelperText>}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} sx={{ paddingTop: "10px !important" }}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="description">References</InputLabel>
                      <TextField
                        multiline
                        fullWidth
                        id="description"
                        placeholder="Enter References"
                        {...getFieldProps("description")}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ paddingTop: "10px !important" }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                      <Stack spacing={1}>
                        {user?.userLevelName !== RoleType.SUPER_ADMIN && user?.userLevelName !== RoleType.ADMIN && (
                          <FormControlLabel
                            value={values.shareAcrossCompany}
                            control={
                              <Checkbox
                                // disabled={user?.userLevelName !== RoleType.COMPANY_ADMIN}
                                checked={values.shareAcrossCompany}
                                id="shareAcrossCompany"
                                {...getFieldProps("shareAcrossCompany")}
                                onChange={handleChange}
                              />
                            }
                            label="Share Across the Company"
                            labelPlacement="end"
                          />
                        )}
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={3}>
                        {/* <InputLabel>Input component using</InputLabel> */}
                        <FormControl component="fieldset">
                          <RadioGroup aria-label="type" defaultValue="grams" name="radio-buttons-group" row>
                            <Box sx={{ fontSize: "20px" }}>
                              <FormControlLabel
                                value="percentage"
                                control={<Radio onChange={handleChangeColorInputType} value="per" checked={calType === "per"} />}
                                label="Use percentage"
                              />
                              <FormControlLabel
                                value="grams"
                                control={<Radio onChange={handleChangeColorInputType} value="gr" checked={calType === "gr"} />}
                                label="Use grams"
                              />
                            </Box>
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper
                      elevation={isMobile ? 0 : 1}
                      sx={(theme) => ({
                        p: 2,
                        [theme.breakpoints.only("xs")]: {
                          px: 0,
                        },
                      })}
                    >
                      <FieldArray
                        name="components"
                        render={(arrayHelpers) => (
                          <Grid item xs={12} sm={12}>
                            <Grid item xs={12} sm={12}>
                              <Stack spacing={2}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                  <Grid container spacing={0} columnSpacing={2}>
                                    <Grid item xs sx={{ pl: "0 !important" }}>
                                      <Typography variant="h6">Component Name</Typography>
                                    </Grid>
                                    {calType === "gr" && (
                                      <Grid item sx={{ width: "170px" }}>
                                        <Typography variant="h6">Weight</Typography>
                                      </Grid>
                                    )}
                                    {calType === "per" && (
                                      <Grid item sx={{ width: "130px" }}>
                                        <Typography variant="h6">Percentage</Typography>
                                      </Grid>
                                    )}
                                    <Grid item sx={{ width: values.components.length > 1 && calType === "per" ? "80px" : "40px" }}></Grid>
                                  </Grid>
                                </Stack>

                                {values.components &&
                                  values.components.map((_component, index) => {
                                    const selectedInk = inks?.find((item: SelectInkType) => item.value === _component.componentId?.toString());
                                    const findedInk = selectedInk || { $id: "0", text: "", value: "0", selected: false };
                                    // let grams=(parseFloat(((values.components[index]?.amount as number*weight)/100).toFixed(2)) )|| 0;
                                    return (
                                      <Stack key={index} direction="row" spacing={2} alignItems="flex-start">
                                        <Grid container spacing={0} columnSpacing={2} wrap="nowrap">
                                          <Grid item xs sx={{ pl: "0 !important" }}>
                                            <Stack spacing={1}>
                                              {inks && (
                                                <Autocomplete
                                                  size="small"
                                                  fullWidth
                                                  disablePortal
                                                  id={`componentId-${index}`}
                                                  options={inks as SelectInkType[]}
                                                  isOptionEqualToValue={(option, value) =>
                                                    option.value === value.value || option.value === inks?.[0].value
                                                  }
                                                  {...getFieldProps(`components[${index}].componentId`)}
                                                  disableClearable={true}
                                                  value={findedInk as SelectInkType}
                                                  onChange={(_e, newValue) => {
                                                    const SelectValue = newValue as SelectInkType;
                                                    setFieldValue(`components[${index}].componentId`, SelectValue.value);
                                                  }}
                                                  getOptionLabel={(label) => label.text}
                                                  renderInput={(params: any) => (
                                                    <TextField
                                                      id={`componentId-${index}`}
                                                      fullWidth
                                                      {...params}
                                                      placeholder={`Select Component  ${index + 1}`}
                                                    />
                                                  )}
                                                />
                                              )}
                                              {touched?.components?.[index]?.componentId && isErrorInComponentId(index) && (
                                                <FormHelperText error>Select Component</FormHelperText>
                                              )}
                                            </Stack>
                                          </Grid>
                                          {calType === "gr" && (
                                            <Grid item container sx={{ width: "170px" }}>
                                              <Grid item xs>
                                                <Stack spacing={1}>
                                                  <OutlinedInput
                                                    size="small"
                                                    id={`amount-${index}`}
                                                    type="number"
                                                    placeholder="Weight"
                                                    value={values.components[index].amountInGrams}
                                                    onFocus={(e) => e.target.select()}
                                                    onChange={(e) => {
                                                      calculateAmount();
                                                      values.components[index].amountInGrams = e.target.value ? parseFloat(e.target.value) : null;
                                                    }}
                                                    endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                                    aria-describedby={`outlined-amount-text-${index}`}
                                                    inputProps={{
                                                      "aria-label": `amount-${index}`,
                                                    }}
                                                  />
                                                  {touched?.components?.[index]?.amount && isErrorInAmountInGrams(index) && (
                                                    <FormHelperText error>Weight is required</FormHelperText>
                                                  )}
                                                </Stack>
                                              </Grid>
                                              <Grid item sx={{ width: "50px", textAlign: "right" }}>
                                                <Typography pt="10px" pl="5px" variant="subtitle2">
                                                  {round(displayIndividualAmountPercentage[index] || 0)}%
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          )}
                                          {calType === "per" && (
                                            <Grid item sx={{ width: "130px" }}>
                                              <Stack direction="column" spacing={1}>
                                                <OutlinedInput
                                                  size="small"
                                                  fullWidth
                                                  id={`Percentage-${index}`}
                                                  type="number"
                                                  placeholder="Percentage"
                                                  // value={grams}
                                                  value={values.components[index].amount || 0}
                                                  onFocus={(e) => e.target.select()}
                                                  onChange={(e) => {
                                                    calculateAmount();
                                                    values.components[index].amount = parseFloat(e.target.value);
                                                  }}
                                                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                  aria-describedby={`outlined-amount-text-${index}`}
                                                  inputProps={{
                                                    "aria-label": `amount-${index}`,
                                                  }}
                                                />
                                                {touched?.components?.[index]?.amount && isErrorInAmount(index) && (
                                                  <FormHelperText error>Percentage is required</FormHelperText>
                                                )}
                                              </Stack>
                                            </Grid>
                                          )}
                                          <Grid item sx={{ width: values.components.length > 1 && calType === "per" ? "80px" : "40px" }}>
                                            <Stack direction="row" spacing={0} justifyContent="flex-end">
                                              {values.components.length > 1 && calType === "per" && (
                                                <Tooltip title="Fill remaining percentage">
                                                  <IconButton
                                                    onClick={() => {
                                                      handleClickCalculateRemainingPer(_component as FormulaComponentItem, index);
                                                      calculateAmount();
                                                    }}
                                                  >
                                                    <ColumnHeightOutlined />
                                                  </IconButton>
                                                </Tooltip>
                                              )}
                                              <Tooltip title="Remove component">
                                                <IconButton
                                                  color="error"
                                                  disabled={values.components.length == 1}
                                                  onClick={() => {
                                                    handleDeleteInkModule(arrayHelpers, index);
                                                    calculateAmount();
                                                  }}
                                                >
                                                  <DeleteOutlined />
                                                </IconButton>
                                              </Tooltip>
                                            </Stack>
                                          </Grid>
                                        </Grid>
                                      </Stack>
                                    );
                                  })}

                                <Stack direction="row" spacing={2} alignItems="center">
                                  <Grid container spacing={0} columnSpacing={2}>
                                    <Grid item xs>
                                      <Stack spacing={1}>
                                        <Typography>Total</Typography>
                                      </Stack>
                                    </Grid>
                                    {calType === "gr" && (
                                      <Grid item container sx={{ width: "170px" }}>
                                        <Grid item xs>
                                          <Typography sx={{ ml: 1 }}>{round(displayTotalGrams)} g</Typography>
                                        </Grid>
                                        <Grid item sx={{ width: "50px", textAlign: "right" }}>
                                          <Typography>{round(displayTotalPercentage)}%</Typography>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {calType === "per" && (
                                      <Grid item sx={{ width: "130px" }}>
                                        <Stack direction="row" justifyContent="space-between">
                                          <Typography color={displayTotalPercentage == 100 ? "default" : "red"} sx={{ ml: 1 }}>
                                            {round(displayTotalPercentage)}%
                                          </Typography>
                                        </Stack>
                                      </Grid>
                                    )}
                                    <Grid item sx={{ width: values.components.length > 1 && calType === "per" ? "80px" : "40px" }}></Grid>
                                  </Grid>
                                </Stack>

                                <Tooltip title="Add Ink Module" placement="top">
                                  <Button
                                    sx={{
                                      width: "50px",
                                      fontSize: "20px",
                                      float: "left",
                                    }}
                                    variant="outlined"
                                    id="add-ink-module"
                                    onClick={() => arrayHelpers.push(initialValues.components[0])}
                                  >
                                    <PlusOutlined />
                                  </Button>
                                </Tooltip>
                              </Stack>
                            </Grid>
                          </Grid>
                        )}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2.5 }}>
            <Button color="secondary" onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Box>
  );
}
