import React from "react";

interface HighlightedTextProps {
  text: string;
  highlight: string;
}

const HighlightedText: React.FC<HighlightedTextProps> = ({ text, highlight }) => {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span style={{ whiteSpace: "preserve" }}>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} style={{ fontWeight: 700, whiteSpace: "nowrap" }}>
            {part}
          </span>
        ) : (
          <span key={index} style={{ whiteSpace: "nowrap" }}>
            {part}
          </span>
        )
      )}
    </span>
  );
};

export default HighlightedText;
