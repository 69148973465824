import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { DownOutlined, LeftOutlined, MenuOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, Stack, CssBaseline, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { ListItemButton } from "@mui/material";
import LangTranslation from "./lang-translate";
import UserProfile from "./user-profile";
import { Divider } from "@mui/material";
import { APP_BAR_HEIGHT, DRAWER_WIDTH, ThemeMode } from "../../config";
import ThemeToggler from "./sidebar-helpers/ThemeToggler";
import { getRoleAccessMenu } from "../../utils/utility";
import { useAppSelector } from "../../redux/hook";
import { UserTypeRedux } from "../../types/user";
import DefaultSeriesSelector from "./DefaultSeriesSelector";
import { Tooltip } from "@mui/material";
import UserFeedback from "./sidebar-helpers/UserFeedback";
// import Typography from '../../themes/typography';

interface NavigationItem {
  label: string;
  url?: string;
  access: boolean;
  children?: NavigationItem[];
}

const drawerWidth = DRAWER_WIDTH;
const appBarHeight = APP_BAR_HEIGHT;

// export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
//   open?: boolean;
// }>(({ theme, open }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(3),
//   transition: theme.transitions.create('margin', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: `-${drawerWidth}px`,
//   ...(open && {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   }),
// }));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'white',
  height: APP_BAR_HEIGHT,
  backgroundColor: theme.palette.mode === ThemeMode.LIGHT ? "white" : "black",
  alignContent: "center",
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function Sidebar() {
  // { children }: {children: React.ReactNode;}
  const pathname = useLocation().pathname;

  const [open, setOpen] = React.useState(true);
  React.useEffect(() => {
    setOpen(true);
  }, []);
  const user: UserTypeRedux = useAppSelector(({ user }) => user.userinfo as UserTypeRedux);
  const roleAuth = getRoleAccessMenu(user?.userLevelName);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  console.log({ user });

  const handleSubMenuToggle = (label: string) => {
    setOpenSubMenu((prevOpenSubMenu) => ({
      ...prevOpenSubMenu,
      [label]: !prevOpenSubMenu[label],
    }));
  };

  const [openSubMenu, setOpenSubMenu] = React.useState<{ [key: string]: boolean }>({
    // Inks: pathname.includes("/inks"),
    // Admin: pathname.includes("/admin")
    Inks: true,
    Admin: true,
  });
  const navLinks: NavigationItem[] = [
    // { label: 'Dashboard', url: '/' },
    { label: "Formulation", url: "/", access: roleAuth.formulation.access },
    { label: "Formula Recycle", url: "/formulation/recycle", access: roleAuth.formulation.recycle.access },
    {
      //   label: "Inventory",
      //   url: "/inventory",
      //   access: roleAuth.inventory.access
      // }, {
      label: "Inventory",
      access: roleAuth.inventory.access,
      children: [
        { label: "Stock Levels", url: "/inventory/stockLevels", access: roleAuth.inventory.stockLevels.access },
        { label: "Stock Entries", url: "/inventory/stockEntries", access: roleAuth.inventory.stockEntries.access },
        { label: "Component Costs", url: "/inventory/componentCosts", access: roleAuth.inventory.componentCosts.access },
        { label: "Inventory Transactions", url: "/inventory/inventoryTransactions", access: roleAuth.inventory.inventoryTransactions.access },
      ],
    },
    {
      label: "Inks",
      children: [
        { label: "Series", url: "/inks/series", access: roleAuth.inks.series.access },
        { label: "Component", url: "/inks/component", access: roleAuth.inks.component.access },
      ],
      access: roleAuth.inks.access,
    },
    {
      label: "Ink Usage Calculator",
      url: "/ink-usage-calculator",
      access: roleAuth.inkcal.access,
    },
    {
      label: "Admin",
      children: [
        { label: "Companies", url: "/admin/company", access: roleAuth.admin.company.access },
        // // { label: "Companies x", url: "/admin/company/edit", access: roleAuth.admin.company.access },
        { label: "Locations", url: "/admin/location", access: roleAuth.admin.location.access },
        { label: "Users", url: "/admin/user", access: roleAuth.admin.user.access },
      ],
      access: roleAuth.admin.access,
    },
  ];

  const NestedListItems: React.FC<{ items: NavigationItem[]; level: number }> = ({ items, level }) => {
    return (
      <>
        {items.map((item) => (
          <React.Fragment key={item.label}>
            {item.children && item.children.length > 0 ? (
              <>
                {item.access && (
                  <ListItemButton onClick={() => handleSubMenuToggle(item.label)}>
                    {/* <ListItemIcon></ListItemIcon> */}
                    <ListItemText primary={item.label} />
                    {openSubMenu[item.label] ? <UpOutlined /> : <DownOutlined />}
                  </ListItemButton>
                )}

                <Collapse in={openSubMenu[item.label]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ pl: 1 }}>
                    <NestedListItems items={item.children} level={level + 1} />
                  </List>
                </Collapse>
              </>
            ) : (
              <>
                {item.access && (
                  <ListItemButton
                    onClick={() => {
                      if (item.url) {
                        navigate(item.url);
                      }

                      if (isMobile) {
                        handleDrawerToggle();
                      }
                    }}
                    sx={{
                      borderRight: pathname === item.url! ? "2px solid" : "",
                      borderRightColor: pathname === item.url! ? "primary.main" : "",
                      bgcolor: pathname === item.url! ? "grey.200" : "",
                    }}
                  >
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        // sx={{ height: APP_BAR_HEIGHT, bgcolor: (theme.palette.mode === ThemeMode.LIGHT && 'secondary.lighter')  }}
      >
        <Toolbar>
          <Stack sx={{ width: "100%" }} direction="row" alignItems="center" justifyContent="space-between">
            <Tooltip title="aaa">
              <IconButton
                onClick={handleDrawerToggle}
                sx={{
                  ...(!open && { display: "none" }),
                }}
              >
                <MenuOutlined />
              </IconButton>
            </Tooltip>
            <Stack direction="row" alignItems="center" justifyContent="space-around">
              <Tooltip title="Open Menu">
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  sx={{
                    mr: 2,
                    ...(open && { display: "none" }),
                    ":hover": {
                      color: "grey",
                    },
                  }}
                >
                  <MenuOutlined />
                </IconButton>
              </Tooltip>
              <DefaultSeriesSelector />
              <ThemeToggler />
              <UserFeedback />
              <UserProfile />
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: isMobile ? "100vw" : drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isMobile ? "100vw" : drawerWidth,
            boxSizing: "border-box",
          },
        }}
        anchor={isMobile ? "top" : "left"}
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <DrawerHeader sx={{ p: 0 }}>
          <img style={{ height: "auto", width: "100%", maxWidth: "300px" }} src={"/images/it-logo.png"} alt="IT-Logo" onClick={handleDrawerToggle} />
        </DrawerHeader>
        <List>
          <NestedListItems items={navLinks} level={0} />
        </List>
        <Divider />
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="body2" color="gray">
            {user?.userLevelDisplayName || <i>No user level</i>}
          </Typography>
          <Typography variant="body2" color="gray">
            Company: {user?.companyName || <i>No company</i>}
          </Typography>
          <Typography variant="body2" color="gray">
            Location: {user?.locationName || <i>No location</i>}
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
}
