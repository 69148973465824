
// material-ui
import ButtonBase from '@mui/material/ButtonBase';

// project import
import { APP_BAR_HEIGHT, DRAWER_WIDTH } from '../../config';
import { Stack } from '@mui/material';

// ==============================|| MAIN LOGO ||============================== //

export default function LogoSection() {
  return (
    <Stack direction='row'>
      <ButtonBase disableRipple>
        <img style={{ width: DRAWER_WIDTH }} src={'/images/it-logo.png'} alt='img-logo' />
      </ButtonBase>
    </Stack>
  );
}
