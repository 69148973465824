import { useEffect, useState, Dispatch, SetStateAction, useMemo } from "react";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Form, Formik } from "formik";

// project imports
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";

// types
import { FormControlLabel, Radio, RadioGroup, Stack, Tooltip, Typography, Switch } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { EndpointsType, FormulaCreationComponentType, FormulaCreationType, SeriesType } from "../../AllTypes";
import { alertMessage } from "../pages-helpers/AlertMessage";
import { ColumnDef } from "@tanstack/react-table";
import DisplayTable from "../display-table-view";
import { formatDate } from "../../utils/formatDate";

function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

interface Props {
  closeModal: () => void;
  formulaCreationRecord?: FormulaCreationType;
}

export default function ViewFormulaCreationRecordModal({ closeModal, formulaCreationRecord }: Props) {
  const [loading, setLoading] = useState<boolean>(true);

  const columns = useMemo<ColumnDef<FormulaCreationComponentType>[]>(
    () => [
      {
        header: "Component",
        accessorKey: "componentName",
        dataType: "text",
        accessorFn: (originalRow) => {
          return originalRow.formulaId ? originalRow.formulaName : originalRow.componentName;
        },
      },
      {
        header: "Weight (g)",
        accessorKey: "weightInGrams",
        dataType: "amount",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <>{formatNo(row.original?.weightInGrams)}</>;
        },
      },
    ],
    []
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <Box>
      <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h4" component="span">
            Formula Creation Record
          </Typography>
        </DialogTitle>
        <Tooltip title="Close">
          <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
            <CloseOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider orientation="horizontal" flexItem />
      <DialogContent sx={{ width: "100%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1}>
                <InputLabel>Formula:</InputLabel>
                <span>{formulaCreationRecord?.formulaName}</span>
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>Created By:&nbsp;</InputLabel>
                {formulaCreationRecord?.createdByFullName}
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>Date Created:&nbsp;</InputLabel>
                {formatDate(formulaCreationRecord?.dateCreated || "", "PPp")}
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>Weight:&nbsp;</InputLabel>
                {formatNo(formulaCreationRecord?.weightInGrams || 0)}g
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>Recycled From:&nbsp;</InputLabel>
                {formulaCreationRecord?.recycledFromName || "None"}
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>Deducted from Inventory:&nbsp;</InputLabel>
                {formulaCreationRecord?.deductedFromInventory ? "Yes" : "No"}
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>Printed:&nbsp;</InputLabel>
                {formulaCreationRecord?.printed ? "Yes" : "No"}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {formulaCreationRecord?.components && (
              <Grid item sm={12}>
                <DisplayTable columns={columns} data={formulaCreationRecord?.components} showAddBtn={true} showPagination={false} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      {/* <Divider /> */}
      <DialogActions sx={{ p: 2.5 }}>
        {/* <Grid item> */}
        {/* <Stack direction="row"> */}
        <Button color="secondary" onClick={closeModal}>
          Close
        </Button>
        {/* </Stack> */}
        {/* </Grid> */}
      </DialogActions>
    </Box>
  );
}
