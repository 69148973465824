import React, { Fragment, MouseEvent, useEffect } from "react";

// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  HeaderGroup,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import { SelectColumnSorting, HeaderSort, TablePagination, RowSelection } from "../../components/third-party/react-table";

// project-import
import IconButton from "../../components/@extended/IconButton";

// types

// assets
import {
  Backdrop,
  Box,
  InputLabel,
  Select,
  OutlinedInput,
  Fade,
  Grid,
  Modal,
  Stack,
  Typography,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Divider,
} from "@mui/material";
import { useMemo, useState } from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import { ComponentCostType, SelectCompanyType } from "../../AllTypes";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import { UserProfile } from "../../types/auth";
import useAuth from "../../hooks/useAuth";
import SetComponentCostModal from "./SetComponentCostModal";
import MainCard from "../../components/MainCard";
import ScrollX from "../../components/ScrollX";
import { TableBody } from "@mui/material";
import { Save } from "@mui/icons-material";

export default function Ink() {
  const { user: currentUser } = useAuth();
  const [search, setSearch] = useState("");
  const [ink, setInk] = useState<ComponentCostType[]>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedComponentId, setSelectedComponentId] = useState(0);

  function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    });
    return formatter.format(pct);
  }

  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    // Set default company if user cannot modify it
    if (currentUser && !hasMinLevel(currentUser, "Admin")) {
      setSelectedCompanyId(currentUser?.companyId?.toString() || "");
    }
  }, [currentUser]);

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }

    setLoading(true);
    axiosServices
      .get(`/api/componentCost/getAll?companyId=${selectedCompanyId}`)
      .then((res) => {
        setInk(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refreshData, selectedCompanyId]);

  useEffect(() => {
    axiosServices
      .get("/api/company/GetAllCompanies")
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    height: "auto",
    maxWidth: "300px",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  // TODO: move to helper class
  function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  function CompanyInput() {
    return hasMinLevel(currentUser, "Admin") ? (
      <>
        <InputLabel htmlFor="companyId">Company</InputLabel>
        <Select id="companyId" fullWidth input={<OutlinedInput />} value={selectedCompanyId} onChange={(e) => setSelectedCompanyId(e.target.value)}>
          {!companies && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {companies &&
            companies?.map((item, index) => (
              <MenuItem key={index} value={parseInt(item.value)}>
                {item.text}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="companyLabel">Company</InputLabel>
        <OutlinedInput fullWidth id="companyLabel" value={currentUser?.companyName} name="companyLabel" readOnly={true} />
      </>
    );
  }

  const columns = useMemo<ColumnDef<ComponentCostType>[]>(
    () => [
      // {
      //   header: 'ID',
      //   accessorKey: 'id',
      //   dataType: 'text'
      // },
      {
        header: "Component",
        accessorKey: "componentName",
        dataType: "text",
      },
      {
        header: "Description",
        accessorKey: "componentDescription",
        dataType: "text",
      },
      {
        header: "Cost (per Gal)",
        accessorKey: "costPerGal",
        dataType: "number",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <>${formatNo(row.original?.costPerGal, 2, 2)}</>;
        },
      },
      // {
      //   header: 'Created By',
      //   accessorKey: 'createdBy',
      //   dataType: 'text',
      // },
      // {
      //   header: "Actions",
      //   id: "actions",
      //   disableSortBy: true,
      //   cell: ({ row }) => {
      //     return (
      //       <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
      //         <Tooltip title="Edit">
      //           <IconButton
      //             color="primary"
      //             onClick={(e: MouseEvent<HTMLButtonElement>) => {
      //               e.stopPropagation();
      //               setSelectedComponentId(row.original.componentId);
      //               handleOpen();
      //             }}
      //           >
      //             <EditOutlined />
      //           </IconButton>
      //         </Tooltip>
      //       </Stack>
      //     );
      //   },
      //   meta: {
      //     className: "cell-center",
      //   },
      // },
    ],
    []
  );

  // function handleOpenAndEditing() {
  //   setSelectedItem({} as ComponentCostType);
  //   setIsEditing(false);
  //   handleOpen();
  // }

  interface ReactTableProps {
    showPagination?: boolean;
    search?: string;
    defaultSorting?: [
      {
        id: string;
        desc: boolean;
      }
    ];
    showFooter?: boolean;
    columns: any;
    data: any;
    modalToggler?: () => void;
    showAddBtn: boolean;
    getRowStyle?: (row: Row<any>) => {};
  }

  function DisplayTable({
    showPagination = true,
    showFooter = false,
    search,
    defaultSorting,
    columns,
    data,
    modalToggler = () => {},
    showAddBtn,
    getRowStyle = (row: Row<any>) => ({}),
  }: ReactTableProps) {
    const [edittingIndex, setEdittingIndex] = useState(-1);
    const [edittingCost, setEdittingCost] = useState(0);

    const [sorting, setSorting] = useState<SortingState>(
      defaultSorting || [
        {
          id: "componentName",
          desc: false,
        },
      ]
    );
    const [rowSelection, setRowSelection] = useState({});
    const [globalFilter, setGlobalFilter] = useState("");

    useEffect(() => {
      setGlobalFilter(search || "");
    }, [search]);

    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
        rowSelection,
        globalFilter,
        columnVisibility: {
          id: false,
        },
      },
      autoResetPageIndex: false,
      getCoreRowModel: getCoreRowModel(),
      debugTable: false,
      enableRowSelection: true,
      onSortingChange: setSorting,
      onRowSelectionChange: setRowSelection,
      onGlobalFilterChange: setGlobalFilter,
      getRowCanExpand: () => true,
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
    });

    return (
      <MainCard content={false}>
        <Box>
          {!data && <Typography>Loading</Typography>}
          {/* <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          alignItems="right"
          justifyContent="end"
          sx={{ padding: 2, ...(downSM && { '& .MuiOutlinedInput-root, & .MuiFormControl-root': { width: '100%' } }) }}
        >
       
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" sx={{ width: { xs: '100%', sm: 'auto' } }}>
            <SelectColumnSorting {...{ getState: table.getState, getAllColumns: table.getAllColumns, setSorting }} />
            <Stack direction="row" spacing={2} alignItems="center">
              {showAddBtn && <Button variant="contained" startIcon={<PlusOutlined />} onClick={modalToggler} >
                Add {from}
              </Button>}
            </Stack>
          </Stack>
        </Stack> */}
          <ScrollX>
            <Stack>
              <RowSelection selected={Object.keys(rowSelection).length} />
              <TableContainer>
                <Table>
                  <TableHead>
                    {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          if (header.column.columnDef.meta !== undefined && header.column.getCanSort()) {
                            Object.assign(header.column.columnDef.meta, {
                              className: header.column.columnDef.meta.className + " cursor-pointer prevent-select",
                            });
                          }
                          return (
                            <TableCell
                              key={header.id}
                              {...header.column.columnDef.meta}
                              onClick={header.column.getToggleSortingHandler()}
                              {...(header.column.getCanSort() &&
                                header.column.columnDef.meta === undefined && {
                                  className: "cursor-pointer prevent-select",
                                })}
                            >
                              {header.isPlaceholder ? null : (
                                <Stack direction="row" spacing={1} alignItems="center">
                                  <Box>{flexRender(header.column.columnDef.header, header.getContext())}</Box>
                                  {header.column.getCanSort() && <HeaderSort column={header.column} />}
                                </Stack>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHead>
                  {table.getRowModel().rows && table.getRowModel().rows.length <= 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: "center" }} colSpan={columns.length}>
                          <Typography sx={{ width: "100%", color: "grey.500" }} component="pre">
                            No data available.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {table.getRowModel().rows.map((row) => (
                        <Fragment key={row.id}>
                          <TableRow sx={getRowStyle(row)}>
                            {row.getVisibleCells().map((cell) => {
                              const cc = row.original as ComponentCostType;

                              const save = () => {
                                const values = { ...cc, costPerGal: edittingCost };
                                axiosServices
                                  .post(`/api/componentCost`, values)
                                  .then((res) => {
                                    alertMessage(`Component cost modified successfully`, "success");
                                    setEdittingIndex(-1);
                                    toggleRefreshData();
                                  })
                                  .catch((err) => {
                                    alertMessage("Something went wrong", "error", err);
                                  });
                              };
                              return cell.column.id === "costPerGal" ? (
                                <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                                  {edittingIndex === row.index && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <TextField
                                        sx={{ width: "80px" }}
                                        value={edittingCost}
                                        onChange={(e) => setEdittingCost(+e.currentTarget.value)}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        autoFocus
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            save();
                                          }
                                        }}
                                      ></TextField>
                                      <IconButton size="small" variant="contained" color="primary" onClick={save}>
                                        <Save />
                                      </IconButton>
                                    </Stack>
                                  )}
                                  {edittingIndex !== row.index && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <span>${formatNo((row.original as ComponentCostType)?.costPerGal, 2, 2)}</span>
                                      <IconButton
                                        color="primary"
                                        onClick={() => {
                                          setEdittingCost(cc.costPerGal || 0);
                                          setEdittingIndex(row.index);
                                        }}
                                      >
                                        <EditOutlined />
                                      </IconButton>
                                    </Stack>
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </Fragment>
                      ))}
                    </TableBody>
                  )}
                  {showFooter && (
                    <TableFooter>
                      {table.getFooterGroups().map((footerGroup) => (
                        <TableRow key={footerGroup.id}>
                          {footerGroup.headers.map((header) => (
                            <TableCell key={header.id}>
                              {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableFooter>
                  )}
                </Table>
              </TableContainer>
              {showPagination && (
                <>
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    <TablePagination
                      {...{
                        setPageSize: table.setPageSize,
                        setPageIndex: table.setPageIndex,
                        getState: table.getState,
                        getPageCount: table.getPageCount,
                      }}
                    />
                  </Box>
                </>
              )}
            </Stack>
          </ScrollX>
        </Box>
      </MainCard>
    );
  }

  return (
    <>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "5%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Grid container>
                <SetComponentCostModal
                  componentId={selectedComponentId}
                  companyId={parseInt(selectedCompanyId) || 0}
                  closeModal={(modified) => {
                    setOpen(false);
                    if (modified) {
                      toggleRefreshData();
                    }
                  }}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="h2">Component Costs</Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={6} alignItems="right">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAndEditing}>
                Add Component
              </Button>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
      <Grid container spacing={1} sx={{ pb: 2 }}>
        <Grid item sm={6} xs={12}>
          <CompanyInput></CompanyInput>
        </Grid>
        {/* <Grid item sm={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid> */}
        <Grid item sm={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
      </Grid>

      {ink && (
        <Stack direction="column" spacing={1}>
          <DisplayTable columns={columns} data={ink} search={search} showAddBtn={false} />
        </Stack>
      )}

      {loading && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {/* <AlertItemDelete
        endpoints={InkEndpoints}
        deleteId={itemDelete?.id?.toString()}
        title="Component"
        deleteName={itemDelete?.componentName || ""}
        open={deleteAlert}
        handleClose={afterDelete}
      /> */}
    </>
  );
}
