import React, { MouseEvent, useEffect } from "react";

// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// project-import
import IconButton from "../../components/@extended/IconButton";

// types

// assets
import { Backdrop, Box, Chip, Fade, Grid, Modal, Stack, Typography, Button, TextField, InputAdornment } from "@mui/material";
import { useMemo, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import AddInkModal from "./ink-helpers/AddInkModal";
import { InkType } from "../../AllTypes";
import DisplayTable from "../display-table-view";
import MainLayout from "../main-layout";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { InkEndpoints } from "../../AllLables";
import axiosServices from "../../utils/axios";
import AlertItemDelete from "../pages-helpers/AlertItemDelete";
import { alertMessage } from "../pages-helpers/AlertMessage";
import SearchOutlined from "@ant-design/icons/SearchOutlined";

export default function Ink() {
  const endpoints = InkEndpoints;
  const [search, setSearch] = useState("");
  const [ink, setInk] = useState<InkType[]>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const emptyState = { name: "", description: "", density: 0, isActive: true };
  const [selectedItem, setSelectedItem] = useState<InkType>(emptyState);
  const [itemDelete, setItemDelete] = useState<InkType>();
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  function afterDelete() {
    setDeleteAlert(false);
    setTimeout(setDataFunction, 500);
  }

  function setDataFunction() {
    axiosServices
      .get(InkEndpoints.get)
      .then((res) => {
        setInk(res.data.items);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      });
  }

  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    setDataFunction();
  }, [refreshData]);

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    height: "auto",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const columns = useMemo<ColumnDef<InkType>[]>(
    () => [
      // {
      //   header: 'ID',
      //   accessorKey: 'id',
      //   dataType: 'text'
      // },
      {
        header: "Name",
        accessorKey: "name",
        dataType: "text",
      },
      {
        header: "Description",
        accessorKey: "description",
        dataType: "text",
      },
      {
        header: "Density (lb/gal)",
        accessorKey: "density",
        dataType: "number",
        meta: {
          className: "cell-center",
        },
      },
      // {
      //   header: 'Created By',
      //   accessorKey: 'createdBy',
      //   dataType: 'text',
      // },
      {
        header: "Status",
        accessorKey: "isActive",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: (cell) => {
          switch (cell.getValue()) {
            case false:
              return <Chip color="error" label="Inactive" size="small" variant="light" />;
            case true:
              return <Chip color="success" label="Active" size="small" variant="light" />;
            default:
              return <Chip color="info" label="Pending" size="small" variant="light" />;
          }
        },
      },
      {
        header: "Actions",
        id: "actions",
        disableSortBy: true,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedItem(row.original as InkType);
                    setIsEditing(true);
                    handleOpen();
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setDeleteAlert(true);
                    setItemDelete(row.original as InkType);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        meta: {
          className: "cell-center",
        },
      },
    ],
    []
  );

  function handleOpenAndEditing() {
    setSelectedItem(emptyState);
    setIsEditing(false);
    handleOpen();
  }

  return (
    <MainLayout>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "5%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddInkModal
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  editingData={selectedItem}
                  endpoints={endpoints}
                  toggleRefreshData={toggleRefreshData}
                  closeModal={() => setOpen(false)}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="h2">Components</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} alignItems="right">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAndEditing}>
                Add Component
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {!ink && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {ink && (
        <Stack direction="column" spacing={1}>
          <TextField
            variant="outlined"
            value={search}
            sx={{ maxWidth: "250px" }}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <DisplayTable columns={columns} data={ink} search={search} modalToggler={handleOpenAndEditing} showAddBtn={true} />
        </Stack>
      )}
      <AlertItemDelete
        endpoints={InkEndpoints}
        deleteId={itemDelete?.id?.toString()}
        title="Component"
        deleteName={itemDelete?.name || ""}
        open={deleteAlert}
        handleClose={afterDelete}
      />
    </MainLayout>
  );
}
