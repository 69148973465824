import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL } from '../backendConfig';

const axiosServices = axios.create({ baseURL: BASE_URL });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('serviceToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 
      && !window.location.href.includes('/login')
      && !window.location.href.includes('/unauthorized-access')
    ) {
      console.log(window.location.href);

      window.location.pathname = '/unauthorized-access';
    }
    return Promise.reject((error.response) || 'Wrong Services');
  }
);

export default axiosServices;

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosServices.get(url, { ...config });

  return res;
};

export const fetcherPost = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.post(url, { ...config });

  return res.data;
};
