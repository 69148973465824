import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  UserReduxState, UserTypeRedux } from "../../types/user";

const USER_INITIAL_STATE:UserReduxState = {
    userinfo: null
};


export const userSlice = createSlice({
    name: "user",
    initialState: USER_INITIAL_STATE,
    reducers: {
        setUserInfo(state: UserReduxState, action: PayloadAction<UserTypeRedux|null>) {
            return {
                ...state,
                userinfo:action.payload 
            };

        },
       
    }

})
export const { setUserInfo} = userSlice.actions
export default userSlice.reducer