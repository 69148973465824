import { combineReducers } from 'redux';
// import countReducer from "./count/count.slice"
// import formulationReducer from "./formulation/formulation.slice"
import userReducer from "./user/user.slice"
import componentReducer from "./component/component.slice" //
// import orderListReducer from "./orderlist/orderlist.slice"
// import createOrderReducer from "./createorder/createorder.slice"
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['formulation','user','components']
}

const rootReducer = combineReducers({
    // count: countReducer,
    user:userReducer,
    // formulation: formulationReducer,
    components:componentReducer
    // createorder:createOrderReducer ,
    // orderlist:orderListReducer
});
// export default persistReducer(persistConfig, rootReducer);

export const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
    persistConfig,
    rootReducer
  );

  export default persistedReducer;