import AuthWrapper from '../../../sections/auth/AuthWrapper';
import ForgotPaswordForm from './ForgotPasswordForm';



// ================================|| FORGOT PASSWORD ||================================ //

export default function ForgotPassword() {
    return (
        <AuthWrapper>
            <ForgotPaswordForm />
        </AuthWrapper>
    );

}