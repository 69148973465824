import { useEffect, useState, Dispatch, SetStateAction } from "react";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Form, Formik } from "formik";

// project imports
import CircularWithPath from "../../../components/@extended/progress/CircularWithPath";

// types
import { Stack, Tooltip, Typography } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { alertMessage } from "../../pages-helpers/AlertMessage";
import axiosServices from "../../../utils/axios";

interface Props {
  closeModal: () => void;
}

export default function AddFeedbackModal({ closeModal }: Props) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const ItemSchema = Yup.object().shape({
    description: Yup.string().max(255).required("Feedback required"),
  });

  const initialValues = {
    description: "",
  };

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ItemSchema}
      enableReinitialize={false}
      onSubmit={async (values, { setSubmitting }) => {
        axiosServices
          .post("/api/UserFeedback", values)
          .then((res) => {
            alertMessage("Feedback submitted successfully", "success");
            closeModal();
          })
          .catch((err) => {
            alertMessage("Something went wrong", "error", err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting, getFieldProps, values }) => (
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                We Value Your Feedback
              </Typography>
            </DialogTitle>
            <Tooltip title="Close">
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent sx={{ width: "500px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      {/* <InputLabel htmlFor="name">Description</InputLabel> */}
                      <TextField
                        fullWidth
                        multiline
                        id="description"
                        placeholder="Tell us what you think..."
                        {...getFieldProps("description")}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2.5 }}>
            <Button color="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              Submit Feedback
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
