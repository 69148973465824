import {
  Chip
} from "@mui/material";

interface Props {
  masterFormula: boolean;
  shareAcrossCompany: boolean;
  longText?: boolean;
  filled?: boolean;
}

export default function FormulaChip({ 
  masterFormula, 
  shareAcrossCompany,
  longText = false,
  filled = false
}: Props) {

  let style = {
    borderColor: '',
    color: '',
    backgroundColor: '',
  };
  let label = '';
  let color = '';
  
  if (masterFormula) {
    color = 'mediumaquamarine';
    label = 'Master';
  } else if (shareAcrossCompany) {
    color = 'salmon';
    label = 'Company';
  } else {
    color = 'mediumpurple';
    label = 'User';
  }

  if (filled) {
    style.backgroundColor = color;
  } else {
    style.borderColor = color;
    style.color = color;
  }
  if (longText) {
    label += " Formula";
  }
  
  return (
    <Chip size="small" color="info" style={style} variant={filled ? 'filled' : 'outlined'} label={label}></Chip> 
  );
}
