import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterComponentReduxUnitialState, MasterComponentType } from "../../types/formulation";

const COMPONENT_INITIAL_STATE:MasterComponentReduxUnitialState = {
    mastercomponents: []
};


export const componentSlice = createSlice({
    name: "component",
    initialState: COMPONENT_INITIAL_STATE,
    reducers: {
        addMasterComponents(state: MasterComponentReduxUnitialState, action: PayloadAction<MasterComponentType[]>) {
            return {
                ...state,
                mastercomponents: action.payload
            };

        },
       
    }

})
export const { addMasterComponents} = componentSlice.actions
export default componentSlice.reducer