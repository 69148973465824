import { useEffect } from 'react'
import MainLayout from '../main-layout'
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    navigate('/formulation');
  },[])
  return (
    <MainLayout>Home</MainLayout>    
  )
}

export default Home