import { Link } from 'react-router-dom';

// material-ui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project import
import { APP_DEFAULT_PATH } from '../../config';
import MainLayout from '../main-layout';

// ==============================|| ERROR 404 - MAIN ||============================== //

export default function NotFound() {
    return (
        <MainLayout>
            <Grid
                container
                spacing={10}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ pt: 1.5, pb: 1, overflow: 'hidden' }}
            >
                <Grid item xs={12}>
                    <Stack spacing={2} justifyContent="center" alignItems="center">
                        <Typography variant="h1">Page Not Found</Typography>
                        <Typography color="text.secondary" align="center" sx={{ width: { xs: '73%', sm: '61%' } }}>
                            The page you are looking was moved, removed, renamed, or might never exist!
                        </Typography>
                        <Button component={Link} to={APP_DEFAULT_PATH} variant="contained">
                            Back To Home
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </MainLayout>
    );
}
