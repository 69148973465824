import { useState, useEffect } from 'react';

// material-ui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

// project import
import { PopupTransition } from '../../../components/@extended/Transitions';
import IconButton from '../../../components/@extended/IconButton';

// assets
import DeleteFilled from '@ant-design/icons/DeleteFilled';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import EyeInvisibleOutlined from '@ant-design/icons/EyeInvisibleOutlined';
import { MailOutlined } from '@ant-design/icons';

// types
import Avatar from '../../../components/@extended/Avatar';
import { alertMessage } from "../../../pages/pages-helpers/AlertMessage";

// third party
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { strengthColor, strengthIndicator } from '../../../utils/password-strength';
import { StringColorProps } from '../../../types/password';

import { Password } from '@mui/icons-material';

import useAuth from '../../../hooks/useAuth';
import axiosServices from "../../../utils/axios";

interface Props {
  // endpoints: EndpointsType;
  // deleteId: string | undefined;
  // deleteName: string;
  // title: string;
  email: string;
  open: boolean;
  handleClose: () => void;
}

// ==============================|| ITEM - DELETE ||============================== //

export default function ResetPasswordModal({ 
  // endpoints,
  // deleteId,
  // deleteName,
  // title,
  email,
  open,
  handleClose 
}: Props) {
  const [resetMode, setResetMode] = useState("mail");
  const [showPassword, setShowPassword] = useState(false);
  const [level, setLevel] = useState<StringColorProps>();
  const { forgotPassword } = useAuth();

  const updateStrenghtIndicator = (value: string) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  function sendResetEmail() {

    forgotPassword(email)
      .then(() => {
        alertMessage(`Reset Password Mail sent to ${email}`, 'success');
      })
      .catch((err) => {
        alertMessage('Something went wrong', 'error', err)
      });
    
  }

  useEffect(() => {
    setResetMode("mail");
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      TransitionComponent={PopupTransition}
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1, width: "500px"}}>
        <Stack spacing={4}>
          <Stack alignItems="center">
            <Typography variant="h4" align="center">
              Reset Password
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ width: 1 }} alignItems="center">
            <Button fullWidth onClick={() => {
              sendResetEmail();
              handleClose();
            }} color={resetMode === "mail" ? "primary" : "inherit"} variant="contained"
              style={{ display: "block" }}>
              Email Reset Link
              <br></br>
              <MailOutlined style={{ fontSize: "40px" }}></MailOutlined>
            </Button>
            <Typography variant="body1" align="center">
              or
            </Typography>
            <Button fullWidth color={resetMode === "manual" ? "primary" : "inherit"} variant="contained" 
              style={{ display: "block" }}
              onClick={() => {
                setResetMode("manual");
              }} autoFocus
            >
              Set Password Manually
              <br></br>
              <Password style={{ fontSize: "40px" }}></Password>
            </Button>
          </Stack>
          { resetMode === "manual" &&
            <Formik
              initialValues={{
                password: '',
                confirmPassword: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .max(255)
                  .min(6, 'Password must have at least 6 characters.')
                  .required('Password is required')
                  .matches(/[0-9]/, 'Password requires a number')
                  .matches(/[a-z]/, 'Password requires a lowercase letter')
                  .matches(/[A-Z]/, 'Password requires an uppercase letter')
                  .matches(/[^\w]/, 'Password requires a symbol'),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                axiosServices.post("/api/account/resetPassword", { newPassword: values.password, email})
                  .then(() => {
                    alertMessage(`Password has been reset.`, 'success')
                    handleClose()
                  })
                  .catch((err) => {
                    alertMessage(`Something went wrong`, 'error', err)
                  })
                // try {
                //   await resetPassword(token, values.password).then(
                //     () => {
                //       setStatus({ success: true });
                //       setSubmitting(false);
                //       alertMessage('Password reset successful', 'success');
                //       navigate('/thank-you-completion', { replace: true });
                //     },
                //     (err) => {
                //       alertMessage('Something went wrong', 'error', err);
                //     }
                //   );
                // } catch (err: any) {
                //   alertMessage('Something went wrong', 'error');
                //   if (scriptedRef.current) {
                //     setStatus({ success: false });
                //     setErrors({ submit: err.message });
                //     setSubmitting(false);
                //   }
                // }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack direction="column" spacing={1.5}>

                    <InputLabel htmlFor="password-reset">Password</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        id="password-reset"
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          updateStrenghtIndicator(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              color="secondary"
                            >
                              {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="Enter password"
                    />
                    {touched.password && errors.password && (
                      <FormHelperText error id="helper-text-password-reset">
                        {errors.password}
                      </FormHelperText>
                    )}
                    <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ pt:2, width: 1 }}>
                      <Button onClick={handleClose} color="secondary">
                        Cancel
                      </Button>
                      <Button type="submit" color="primary" variant="contained" autoFocus>
                        Reset Password
                      </Button>
                    </Stack>
                  </Stack>

                </Form>
                
              )}
            </Formik>
          }
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
