import AuthWrapper from '../../../sections/auth/AuthWrapper';
import LoginForm from './LoginForm';


// ================================|| LOGIN ||================================ //

export default function Login() {
    return (
        <AuthWrapper>
            <LoginForm />
        </AuthWrapper>
    );

}