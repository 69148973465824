import { MouseEvent, useCallback, useEffect } from 'react';

// material-ui
import Tooltip from '@mui/material/Tooltip';

// third-party
import {
  ColumnDef,
} from '@tanstack/react-table';

// project-import
import IconButton from '../../components/@extended/IconButton';

// assets
import { Backdrop, Box, Fade, Grid, Modal, Stack, Typography, Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import AddSeriesModal from './series-helpers/AddSeriesModal';
import { SeriesType } from '../../AllTypes';
import DisplayTable from '../display-table-view';
import MainLayout from '../main-layout';
import CircularWithPath from '../../components/@extended/progress/CircularWithPath';
import { SeriesEndpoints } from '../../AllLables';
import axiosServices from '../../utils/axios';
import { Chip } from '@mui/material';
import AlertItemDelete from '../pages-helpers/AlertItemDelete';
import { alertMessage } from '../pages-helpers/AlertMessage';

export default function Series() {
  const [series, setSeries] = useState<SeriesType[]>();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [refreshData, setRefreshData] = useState<boolean>(false)

  const seriesEmptyState = { name: '', isActive: true }
  const [selectedItem, setSelectedItem] = useState<SeriesType>(seriesEmptyState);
  const [itemDelete, setItemDelete] = useState<SeriesType>();
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const deleteAlertClose = useCallback(() => setDeleteAlert(false), []);

  function setDataFunction() {
    axiosServices.get(SeriesEndpoints.get)
      .then(res => {
        setSeries(res.data.items)
      })
      .catch(err => {
        alertMessage("Something went wrong", "error");
      })
  }

  const toggleRefreshData = () => { setRefreshData(!refreshData); };


  useEffect(() => { setDataFunction(); }, [refreshData]);

  const modalStyle = {
    position: 'absolute',
    display: 'flex',
    width: 'auto',
    height: 'auto',
    alignContent: 'center',
    justifyContent: 'center',
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  const columns = useMemo<ColumnDef<SeriesType>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        dataType: 'text',
        enableSorting: false,
      },
      {
        header: 'Name',
        accessorKey: 'name',
        dataType: 'text'
      },
      {
        header: 'Master Formulas',
        accessorKey: 'masterFormulaCount',
        dataType: 'number',
        meta: {
          className: 'cell-center'
        },
      },
      // {
      //   header: 'Created By',
      //   accessorKey: 'createdBy',
      //   dataType: 'text',
      // },
      {
        header: 'Status',
        accessorKey: 'isActive',
        dataType: 'text',
        meta: {
          className: 'cell-center'
        },
        cell: (cell) => {
          switch (cell.getValue()) {
            case false:
              return <Chip color="error" label="Inactive" size="small" variant="light" />;
            case true:
              return <Chip color="success" label="Active" size="small" variant="light" />;
            default:
              return <Chip color="info" label="Pending" size="small" variant="light" />;
          }
        }
      },
      {
        header: 'Actions',
        id: 'actions',
        disableSortBy: true,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedItem(row.original as SeriesType);
                    setIsEditing(true);
                    handleOpen();
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setDeleteAlert(true);
                    setItemDelete(row.original as SeriesType);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },

        meta: {
          className: 'cell-center'
        }
      },
    ],
    []
  );

  function handleOpenAndEditing() {
    setSelectedItem(seriesEmptyState);
    setIsEditing(false);
    handleOpen();
  }

  function afterDelete() {
    deleteAlertClose();
    setTimeout(setDataFunction, 500);
  }

  return (
    <MainLayout>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "5%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddSeriesModal
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  editingData={selectedItem}
                  endpoints={SeriesEndpoints}
                  toggleRefreshData={toggleRefreshData}
                  closeModal={() => setOpen(false)}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Grid item container xs={12}>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="h2">
                  Series
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} alignItems="right">
              <Box sx={{ display: "flex", justifyContent:"flex-end"}}>
                <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAndEditing} >
                  Add Series
                </Button>
              </Box>
            </Grid>
        </Grid>
      </Box>

      {!series && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {series && (
        <DisplayTable
          columns={columns}
          data={series}
          modalToggler={handleOpenAndEditing}
          showAddBtn={true}
        />
      )}
      <AlertItemDelete
        endpoints={SeriesEndpoints}
        deleteId={itemDelete?.id?.toString()}
        title="Series"
        deleteName={itemDelete?.name || ""}
        open={deleteAlert}
        handleClose={afterDelete}
      />
    </MainLayout>
  );

}