import { useEffect, useState } from "react";
import useConfig from "../../../hooks/useConfig";
import { ThemeMode } from "../../../config";
import { Box, IconButton, Tooltip } from "@mui/material";
import { MoonOutlined, SunFilled } from "@ant-design/icons";
import { DarkMode, DarkModeOutlined, LightMode } from "@mui/icons-material";

const ThemeToggler = () => {
  const [checked, setChecked] = useState<string>();
  const { mode, onChangeMode } = useConfig();

  useEffect(() => {
    mode === "light" ? setChecked("light") : setChecked("dark");
  }, [mode]);

  const handleModeChange = () => {
    const changeModeTo = (modeType: string) => {
      localStorage.setItem("it-app-config", modeType);
      onChangeMode(modeType as ThemeMode);
      setChecked(modeType);
    };

    mode === "light" ? changeModeTo("dark") : changeModeTo("light");
  };

  return (
    <Box>
      <Tooltip title="Change Mode">
        <IconButton onClick={handleModeChange}>
          {checked === "dark" ? <LightMode fontSize="small" /> : <DarkModeOutlined fontSize="small" />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ThemeToggler;
