// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// project-import
import FormulaChip from "../../components/FormulaChip";

// types

// assets
import {
  Backdrop,
  Box,
  ButtonGroup,
  Fade,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
  Divider,
  Link,
  Button,
  useTheme,
  useMediaQuery,
  MenuItem,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CopyFilled, DeleteFilled, EditFilled, PlusSquareFilled, PrinterFilled } from "@ant-design/icons";

import { GetFormulaType, SelectSeriesType, FormulaListItemType } from "../../AllTypes";
import DisplayTable from "../display-table-view";
import MainLayout from "../main-layout";
import AddFormulaModal from "./formulation-helpers/AddFormulaModal";
import ShareFormulaModal from "./formulation-helpers/ShareFormulaModal";
import { FormulaEndpoints, SeriesEndpoints } from "../../AllLables";
import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";
import Stop from "@mui/icons-material/Stop";
import Recycling from "@mui/icons-material/Recycling";
import AlertItemDelete from "../pages-helpers/AlertItemDelete";
import { formatDate } from "../../utils/formatDate";
import { useNavigate, Link as RouterLink } from "react-router-dom";

import { useAppSelector } from "../../redux/hook";
import { FormulaComponentItem } from "../../types/formulation";
import PrintFormulaModal from "./formulation-helpers/PrintFormulaModal";
import ConfirmDeductFromInventory from "./formulation-helpers/ConfirmDeductFromInventory";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { UserTypeRedux } from "../../types/user";
import { RoleType } from "../../utils/utility";
import useStickyState from "../../hooks/useStickyState";
import "./styles.css";
import { Article, MoveDown, Email, MoreHoriz, Print, Delete, Edit, CopyAll } from "@mui/icons-material";
import { Menu } from "@mui/material";
import { ListItemIcon } from "@mui/material";
import { formatNo } from "../../utils/utility";

export default function Formulation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const predefinedWeights = [
    { id: 1, label: "100 g", weight: 100 },
    { id: 1, label: "500 g", weight: 500 },
    { id: 2, label: "1 kg", weight: 1000 },
  ];

  const navigate = useNavigate();

  const [includeMaster, setIncludeMaster] = useState(true);
  const [includeCompany, setIncludeCompany] = useState(true);
  const [includeUser, setIncludeUser] = useState(true);
  const [formulas, setFormulas] = useState<GetFormulaType[]>();
  const [formulaOptions, setFormulaOptions] = useState<FormulaListItemType[]>([]);
  const [cloneFormula, setCloneFormula] = useState<boolean>(false);
  const [edittingFormulaId, setEdittingFormulaId] = useState<number | null>(null);
  const [selectedFormulaId, setSelectedFormulaId] = useState<number | null>(null);
  const [selectedFormulaOption, setSelectedFormulaOption] = useState<FormulaListItemType | null>(null);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState(false);
  const [weightValue, setweightValue] = useState<number>(1000);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [showConfirmDeductFormulaModal, setShowConfirmDeductFormulaModal] = useState<boolean>(false);
  const [showShareFormulaModal, setShowShareFormulaModal] = useState<boolean>(false);
  const [selectedFormula, setSelectedFormula] = useState<GetFormulaType | null>(null);
  const user: UserTypeRedux = useAppSelector(({ user }) => user.userinfo as UserTypeRedux);
  const [open, setOpen] = useState(false);

  const [boxColor, setBoxColor] = useState<string | null>(null);
  const [itemDelete, setItemDelete] = useState<GetFormulaType>();
  const [deleteAlert, setDeleteAlert] = useState<boolean>();
  const deleteAlertClose = () => setDeleteAlert(false);

  const [series, setSeries] = useState<SelectSeriesType[]>();
  const [selectedSeries, setSelectedSeries] = useState<SelectSeriesType>();
  const [defaultSeriesId, setDefaultSeriesId] = useStickyState<string | null>(null, "defaultSeriesId");

  const handleOpen = () => setOpen(true);
  const handleClose = (updatedFormulaId?: number) => {
    if (updatedFormulaId) {
      setSelectedFormulaId(updatedFormulaId);
      setFormulaDetails(updatedFormulaId);
    }
    setOpen(false);
  };

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    height: "auto",
    maxWidth: "900px",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "auto",
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMoreOptionsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMoreOptionsMenu = () => {
    setAnchorEl(null);
  };

  function isAdmin() {
    return user.userLevelName === RoleType.ADMIN || user.userLevelName === RoleType.SUPER_ADMIN;
  }

  function setDataFunction() {
    axiosServices
      .get(SeriesEndpoints.getForSelect)
      .then((res) => {
        setSeries(res.data);

        // Set the default series or the first series as selected
        if (!selectedSeries) {
          let ss = res.data[0];
          if (defaultSeriesId) {
            let defaultSeries = res.data.find((d: SelectSeriesType) => d.value === defaultSeriesId);
            if (defaultSeries) {
              ss = defaultSeries;
            }
          }
          setSelectedSeries(ss);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong ", "error", err);
      });
  }

  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    setDataFunction();
  }, []);

  // useEffect(() => {
  //   // Updates the default series id
  //   setDefaultSeriesId(selectedSeries?.value || null);
  // }, [selectedSeries]);

  useEffect(() => {
    if (selectedSeries) {
      setisLoading(true);
      axiosServices
        .get(
          `/api/Formula/GetAll?seriesId=${selectedSeries.value}&includeMaster=${includeMaster}&includeCompany=${includeCompany}&includeUser=${includeUser}`
        )
        // .get(
        //   FormulaEndpoints.getFormulaComponent(parseInt(selectedSeries.value))
        // )
        .then((res) => {
          let formulas = res.data;
          setFormulas(formulas);
          let options: FormulaListItemType[] = formulas.map((f: GetFormulaType) => ({
            text: f.name,
            value: f.id,
            selected: false,
          }));
          options.sort((a, b) => {
            if (a.text < b.text) {
              return -1;
            }
            if (a.text > b.text) {
              return 1;
            }
            return 0;
          });
          setFormulaOptions(options);

          if (selectedFormulaId || formulas?.[0]) {
            let fId = selectedFormulaId || formulas[0].id || 0;
            setSelectedFormulaId(fId);
            let option = options.find((f) => f.value === fId);
            if (option) {
              setSelectedFormulaOption(option);
            }
          }
        })
        .catch((err) => {
          alertMessage("Something went wrong ", "error", err);
        })
        .finally(() => {
          setisLoading(false);
        });
    }
  }, [selectedSeries, includeCompany, includeMaster, includeUser, refreshData]);

  function setFormulaDetails(formulaId: number) {
    axiosServices
      .get(FormulaEndpoints.getById(formulaId.toString()))
      .then((res) => {
        console.log({ formula: res.data });
        setSelectedFormula(res.data);
        setBoxColor(res.data.rgbColors);
      })
      .catch((err) => {
        alertMessage("Something went wrong ", "error", err);
      });
  }

  useEffect(() => {
    if (selectedFormulaId) {
      setFormulaDetails(selectedFormulaId);
    } else {
      setSelectedFormula(null);
    }
  }, [selectedFormulaId]);

  const columns = useMemo<
    ColumnDef<{
      componentName: string;
      componentDescription: string;
      amount: number;
      amountInGrams: number;
    }>[]
  >(
    () => [
      {
        header: "Product Code",
        accessorKey: "componentName",
        dataType: "text",
      },
      {
        header: "Description",
        accessorKey: "componentDescription",
        dataType: "text",
      },
      {
        header: "Percentage",
        accessorKey: "amount",
        dataType: "amount",
        cell: ({ row }) => {
          return <>{formatNo(row.original?.amount, 3, 0)}%</>;
        },
      },
      {
        header: "Amount",
        accessorKey: "amountInGrams",
        dataType: "text",
        cell: ({ row }) => {
          return <>{formatNo(row.original?.amountInGrams, 3, 0)} g</>;
        },
      },
    ],
    []
  );

  const columnsForMobile = useMemo<
    ColumnDef<{
      componentName: string;
      componentDescription: string;
      amount: number;
      amountInGrams: number;
    }>[]
  >(
    () => [
      {
        header: "Product",
        accessorKey: "componentName",
        dataType: "text",
        accessorFn: (originalRow) => {
          return `${originalRow.componentName} - ${originalRow.componentDescription}`;
        },
      },
      {
        header: "Amount",
        accessorKey: "amount",
        dataType: "amount",
        cell: ({ row }) => {
          return (
            <>
              {formatNo(row.original?.amount, 3, 0)}% ({formatNo(row.original?.amountInGrams, 3, 0)} g)
            </>
          );
        },
      },
    ],
    []
  );

  function setWeightFromPredefined(data: number) {
    const ele = document.getElementById("weight-input") as HTMLInputElement;
    if (ele) ele.value = data.toString();
    setweightValue(data);
  }

  function getDisplayFormulaSeriesData() {
    const displayComponents: FormulaComponentItem[] = [];
    if (!selectedFormula) {
      return displayComponents;
    }

    const selectedFormulaComponents: FormulaComponentItem[] = selectedFormula?.components as FormulaComponentItem[];
    let components = JSON.parse(JSON.stringify(selectedFormulaComponents));
    if (components) {
      for (let j = 0; j < components.length; j++) {
        components[j].amountInGrams = ((components[j].amount * weightValue) / 100).toFixed(2);
        displayComponents.push(components[j]);
      }
    }
    return displayComponents?.filter((item) => item.isDeleted === false);
  }

  function setDataForEditing() {
    if (selectedFormulaId) {
      setEdittingFormulaId(selectedFormulaId);
      setCloneFormula(false);
      handleOpen();
      // }
    } else {
      alertMessage("No Formula Selected", "error");
    }
  }
  function setDataForDeleting() {
    if (selectedFormulaId) {
      if (selectedFormula) {
        setItemDelete(selectedFormula);
        setDeleteAlert(true);
      }
    } else {
      alertMessage("No Formula Selected", "error");
    }
  }

  function setDataForAdding() {
    if (selectedSeries) {
      // setIsEditing(false);
      setEdittingFormulaId(null);
      handleOpen();
    } else {
      alertMessage(`Select series before adding formula.`, "error");
    }
  }

  function setDataForCloning() {
    if (selectedFormulaId) {
      setEdittingFormulaId(selectedFormulaId);
      setCloneFormula(true);
      handleOpen();
    } else {
      alertMessage("No Formula Selected", "error");
    }
  }

  const handleChangeWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setweightValue(0);
    } else {
      setweightValue(parseInt(e.target.value));
    }
  };

  const buttonStyles = {
    whiteSpace: "nowrap",
    minWidth: "max-content",
  };

  return (
    <MainLayout>
      <Box>
        {open && (
          <Modal
            open={open}
            onClose={() => handleClose()}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              mt: "2%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Fade in={open}>
              <Box sx={modalStyle}>
                <Grid container>
                  <AddFormulaModal
                    open={open}
                    seriesId={parseInt(selectedSeries?.value || "0")}
                    formulaId={edittingFormulaId}
                    cloneFormula={cloneFormula}
                    closeModal={handleClose}
                    toggleRefreshData={toggleRefreshData}
                    endpoints={FormulaEndpoints}
                  />
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}
        {showPrintModal && (
          <Modal
            open={showPrintModal}
            onClose={() => setShowPrintModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              mt: "2%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Fade in={showPrintModal}>
              <Box sx={modalStyle}>
                <Grid container>
                  <PrintFormulaModal
                    data={getDisplayFormulaSeriesData() as FormulaComponentItem[]}
                    // open={isCloning}
                    weight={weightValue}
                    formula={selectedFormula}
                    series={selectedSeries!}
                    showPrintModal={showPrintModal}
                    setShowPrintModal={setShowPrintModal}
                    // editingData={selectedItem}
                    // selectedSeries={selectedSeries as SelectSeriesType}
                    endpoints={FormulaEndpoints}
                    closeModal={() => setShowPrintModal(false)}
                  />
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}
        {showConfirmDeductFormulaModal && (
          <Modal
            open={showConfirmDeductFormulaModal}
            onClose={() => setShowConfirmDeductFormulaModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              mt: "2%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Fade in={showConfirmDeductFormulaModal}>
              <Box sx={modalStyle}>
                <Grid container>
                  <ConfirmDeductFromInventory
                    data={getDisplayFormulaSeriesData() as FormulaComponentItem[]}
                    weight={weightValue}
                    formula={selectedFormula}
                    series={selectedSeries!}
                    closeModal={() => setShowConfirmDeductFormulaModal(false)}
                  />
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}

        {showShareFormulaModal && (
          <Modal
            open={showShareFormulaModal}
            onClose={() => setShowShareFormulaModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              mt: "2%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Fade in={showShareFormulaModal}>
              <Box sx={modalStyle}>
                <Grid container>
                  <ShareFormulaModal weight={weightValue} formulaId={selectedFormulaId || 0} closeModal={() => setShowShareFormulaModal(false)} />
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}
      </Box>

      <Box sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} lg={12}>
          <Stack direction="row" justifyContent="space-between">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h2">Formulation</Typography>
            </Box>
            {/* <Link component={RouterLink} to="/formulation/log" color="inherit" underline="none">
              View Log
            </Link> */}
            <Button size="small" onClick={() => navigate("/formulation/log")} color="inherit" startIcon={<Article />}>
              View Log
            </Button>
          </Stack>
        </Grid>
      </Box>

      <Grid item container xs={12}>
        <Grid item container sm={12} spacing={1}>
          <Grid item xs={12} sm>
            <Stack spacing={1}>
              <InputLabel htmlFor="series">Series</InputLabel>
              {series && (
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="combo-box-demo"
                  options={series as SelectSeriesType[]}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  disableClearable={true}
                  // defaultValue={selectedSeries}
                  // inputValue={selectedSeries?.value as string}
                  value={selectedSeries as SelectSeriesType}
                  onChange={(_e, newValue) => {
                    setSelectedFormulaId(null);
                    setSelectedFormulaOption(null);
                    // setSelectedComponent(null)
                    setSelectedSeries(newValue as SelectSeriesType);
                  }}
                  // sx={{ width: 300 }}
                  getOptionLabel={(label) => label.text}
                  getOptionKey={(label) => label.value}
                  renderInput={(params: any) => <TextField fullWidth {...params} placeholder="Select Series" />}
                />
              )}
            </Stack>
          </Grid>
          {/* <Grid item container xs={12} sm={8}> */}
          <Grid item>
            <Stack spacing={1}>
              <InputLabel>Include Types</InputLabel>
              <ButtonGroup>
                {/* TODO: create theme for custom color #509bc5 secondary or #95afe8 or #476bba main */}
                {/* TODO: style with no elevation */}
                <Button variant={includeMaster ? "contained" : "outlined"} color="primary" onClick={() => setIncludeMaster(!includeMaster)}>
                  Master
                </Button>
                <Button variant={includeCompany ? "contained" : "outlined"} color="primary" onClick={() => setIncludeCompany(!includeCompany)}>
                  Company
                </Button>
                <Button variant={includeUser ? "contained" : "outlined"} color="primary" onClick={() => setIncludeUser(!includeUser)}>
                  User
                </Button>
              </ButtonGroup>
            </Stack>
          </Grid>
          {/* </Grid> */}
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="formula">Formula</InputLabel>
              {/* {selectedFormulaOption?.value} */}
              {formulas && (
                <Autocomplete
                  fullWidth
                  id="combo-box-demo1"
                  getOptionLabel={(label) => label.text}
                  options={formulaOptions}
                  // options={formula?.formulaListItems as FormulaListItemType[] }
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  value={selectedFormulaOption as FormulaListItemType}
                  disableClearable={true}
                  onChange={(_e, newValue) => {
                    const formulaCmp = formulas.find((item) => item.id === parseInt(newValue!.value as string));
                    setSelectedFormulaId(parseInt(newValue?.value as string));
                    setSelectedFormulaOption(newValue as FormulaListItemType);
                    // setSelectedComponent(formulaCmp as GetFormulaType)
                    setBoxColor(formulaCmp?.rgbColors || null);
                  }}
                  renderOption={(props, option) => {
                    const formulaCmp = formulas.find((item) => item.id === parseInt(option.value as string));
                    const x: any = props;
                    delete x["key"];
                    return (
                      // <Box direction="row" alignItems="center" sx={{cursor:"pointer"}} >
                      <Fragment key={`a${option!.value}`}>
                        {formulaCmp && (
                          <Box {...x} sx={{ cursor: "pointer" }} component="li" display="inline">
                            <Stop sx={{ fontSize: "30px", color: `${formulaCmp?.rgbColors}` }} />
                            {option.text}
                            &nbsp;
                            {formulaCmp && (
                              <FormulaChip masterFormula={formulaCmp?.masterFormula} shareAcrossCompany={formulaCmp.shareAcrossCompany}></FormulaChip>
                            )}
                          </Box>
                        )}
                      </Fragment>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      fullWidth
                      {...params}
                      placeholder="Select Formula"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Stop sx={{ fontSize: "30px", color: `${selectedFormula?.rgbColors}` }} />

                            {selectedFormula && (
                              <FormulaChip
                                masterFormula={selectedFormula?.masterFormula}
                                shareAcrossCompany={selectedFormula.shareAcrossCompany}
                              ></FormulaChip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                  )}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid item sm={12} sx={{ pt: 2, pb: 1 }}>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              <Tooltip title="Add Formula" placement="top">
                <span>
                  <Button sx={buttonStyles} size="small" onClick={setDataForAdding} variant="contained" startIcon={<PlusSquareFilled />}>
                    Add Formula
                  </Button>
                </span>
              </Tooltip>

              {isMobile ? (
                <>
                  <Button sx={buttonStyles} size="small" onClick={openMoreOptionsMenu} variant="outlined" startIcon={<MoreHoriz />}>
                    More
                  </Button>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMoreOptionsMenu} PaperProps={{ style: { maxHeight: 500 } }}>
                    <MenuItem
                      onClick={() => {
                        closeMoreOptionsMenu();
                        setDataForCloning();
                      }}
                      disabled={!selectedFormulaOption}
                    >
                      <ListItemIcon>
                        <CopyAll />
                      </ListItemIcon>
                      Clone
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        closeMoreOptionsMenu();
                        setDataForEditing();
                      }}
                      disabled={
                        !selectedFormulaOption ||
                        (selectedFormula?.masterFormula && user?.userLevelName !== RoleType.ADMIN && user?.userLevelName !== RoleType.SUPER_ADMIN)
                      }
                    >
                      <ListItemIcon>
                        <Edit />
                      </ListItemIcon>
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        closeMoreOptionsMenu();
                        setDataForDeleting();
                      }}
                      disabled={
                        !selectedFormulaOption ||
                        (selectedFormula?.masterFormula && user?.userLevelName !== RoleType.ADMIN && user?.userLevelName !== RoleType.SUPER_ADMIN)
                      }
                    >
                      <ListItemIcon>
                        <Delete />
                      </ListItemIcon>
                      Delete
                    </MenuItem>
                    <Divider></Divider>
                    <MenuItem
                      onClick={() => {
                        closeMoreOptionsMenu();
                        navigate(`/formulation/recycle?formulaId=${selectedFormulaId}`);
                      }}
                      disabled={!selectedFormulaOption}
                    >
                      <ListItemIcon>
                        <Recycling />
                      </ListItemIcon>
                      Recycle
                    </MenuItem>
                    <Divider></Divider>
                    <MenuItem
                      onClick={() => {
                        closeMoreOptionsMenu();
                        setShowPrintModal(true);
                      }}
                      disabled={!selectedFormulaOption}
                    >
                      <ListItemIcon>
                        <Print />
                      </ListItemIcon>
                      Print
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        closeMoreOptionsMenu();
                        setShowConfirmDeductFormulaModal(true);
                      }}
                      disabled={!selectedFormulaOption}
                    >
                      <ListItemIcon>
                        <MoveDown />
                      </ListItemIcon>
                      Deduct Inventory
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        closeMoreOptionsMenu();
                        setShowShareFormulaModal(true);
                      }}
                      disabled={!selectedFormulaOption}
                    >
                      <ListItemIcon>
                        <Email />
                      </ListItemIcon>
                      Email Formula
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <Tooltip title="Clone Formula" placement="top">
                    <span>
                      <Button
                        sx={buttonStyles}
                        size="small"
                        onClick={setDataForCloning}
                        color="primary"
                        variant="contained"
                        disabled={!selectedFormulaOption}
                        startIcon={<CopyAll />}
                      >
                        Clone
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title="Edit Formula" placement="top">
                    <span>
                      <Button
                        sx={buttonStyles}
                        size="small"
                        color="primary"
                        variant="contained"
                        disabled={
                          !selectedFormulaOption ||
                          (selectedFormula?.masterFormula && user?.userLevelName !== RoleType.ADMIN && user?.userLevelName !== RoleType.SUPER_ADMIN)
                        }
                        onClick={setDataForEditing}
                        startIcon={<Edit />}
                      >
                        Edit
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete Formula" placement="top">
                    <span>
                      <Button
                        sx={buttonStyles}
                        size="small"
                        onClick={setDataForDeleting}
                        color="error"
                        variant="outlined"
                        disabled={
                          !selectedFormulaOption ||
                          (selectedFormula?.masterFormula && user?.userLevelName !== RoleType.ADMIN && user?.userLevelName !== RoleType.SUPER_ADMIN)
                        }
                        startIcon={<Delete />}
                      >
                        Delete
                      </Button>
                    </span>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem></Divider>
                  <Tooltip title="Recycle Formula" placement="top">
                    <span>
                      <Button
                        sx={buttonStyles}
                        size="small"
                        color="success"
                        variant="contained"
                        disabled={!selectedFormulaOption}
                        // onClick={setDataForRecycling}
                        onClick={() => navigate(`/formulation/recycle?formulaId=${selectedFormulaId}`)}
                        startIcon={<Recycling />}
                      >
                        Recycle
                      </Button>
                    </span>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem></Divider>
                  <Tooltip title="Print Formula" placement="top">
                    <span>
                      <Button
                        sx={buttonStyles}
                        size="small"
                        variant="contained"
                        onClick={() => setShowPrintModal(true)}
                        disabled={!selectedFormulaOption}
                        startIcon={<Print />}
                      >
                        Print
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title="Deduct from Inventory" placement="top">
                    <span>
                      <Button
                        sx={buttonStyles}
                        size="small"
                        variant="contained"
                        onClick={() => setShowConfirmDeductFormulaModal(true)}
                        disabled={!selectedFormulaOption}
                        startIcon={<MoveDown />}
                      >
                        Deduct Inventory
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title="Share by Email" placement="top">
                    <span>
                      <Button
                        sx={buttonStyles}
                        size="small"
                        variant="contained"
                        onClick={() => setShowShareFormulaModal(true)}
                        disabled={!selectedFormulaOption}
                        startIcon={<Email />}
                      >
                        Email Formula
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item container sm={12} spacing={1}>
          <Grid item xs={12} sm={9}>
            {(formulas || series) && !isLoading ? (
              <DisplayTable
                columns={isMobile ? columnsForMobile : columns}
                showPagination={false}
                defaultSorting={[{ id: "amount", desc: true }]}
                data={getDisplayFormulaSeriesData()}
                modalToggler={handleOpen}
                showAddBtn={false}
              />
            ) : (
              <Box sx={{ p: 5 }}>
                <Stack direction="row" justifyContent="center">
                  <CircularWithPath />
                </Stack>
              </Box>
            )}
            <Grid container>
              <Grid item>
                <Box
                  sx={{
                    mt: "20px",
                    boxShadow: "0px 1px 5px black",
                    backgroundColor: boxColor,
                    height: "100px",
                    width: "150px",
                    borderRadius: "5px",
                  }}
                ></Box>
              </Grid>
              <Grid item sx={{ p: 3 }}>
                {selectedFormula && (
                  <Stack direction="column" spacing={1}>
                    <span>
                      <FormulaChip
                        masterFormula={selectedFormula?.masterFormula}
                        shareAcrossCompany={selectedFormula.shareAcrossCompany}
                        longText
                      ></FormulaChip>
                    </span>

                    {selectedFormula?.masterFormula === false && (
                      // isAdmin() && // TODO: uncomment this to avoid showing when is a company user
                      <Typography variant="body2">Company: {selectedFormula.companyName}</Typography>
                    )}
                    {selectedFormula?.masterFormula === false && selectedFormula?.shareAcrossCompany === false && (
                      <Typography variant="body2">Location: {selectedFormula.locationName}</Typography>
                    )}
                    <Typography variant="body2">References:</Typography>
                    <ul
                      style={{
                        margin: 0,
                        paddingLeft: 20,
                      }}
                    >
                      {selectedFormula?.description?.split("\n").map((r, index) => (
                        <li key={index}>
                          <Typography variant="body2">{r}</Typography>
                        </li>
                      ))}
                    </ul>
                    <Typography variant="body2">
                      Created by <i>{selectedFormula?.createdByFullName}</i> on{" "}
                      <i>{formatDate(selectedFormula?.dateCreated || "", "MMMM d, yyyy")}</i>
                      <br />
                      {selectedFormula?.updatedBy && (
                        <span>
                          Last updated by <i>{selectedFormula?.updatedByFullName}</i> on{" "}
                          <i>{formatDate(selectedFormula?.lastUpdated || "", "MMMM d, yyyy")}</i>
                        </span>
                      )}
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <FormHelperText sx={{ fontSize: "15px" }} id="outlined-weight-helper-text">
                Weight
              </FormHelperText>
              <OutlinedInput
                id="weight-input"
                type="number"
                defaultValue={1000}
                onChange={handleChangeWeight}
                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                aria-describedby="outlined-weight-text"
                sx={{ fontSize: 20 }}
                inputProps={{
                  "aria-label": "weight",
                }}
              />

              <Grid item xs={12} sm={12}>
                <ButtonGroup variant="contained" color="primary" fullWidth>
                  {predefinedWeights.length > 0 &&
                    predefinedWeights.map((value, index) => (
                      <Button onClick={() => setWeightFromPredefined(value.weight)} key={index} className="weight-button">
                        {value.label}
                      </Button>
                    ))}
                </ButtonGroup>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      {/* </Grid> */}

      <AlertItemDelete
        endpoints={FormulaEndpoints}
        deleteId={itemDelete?.id?.toString()}
        title="Formula"
        deleteName={itemDelete?.name || ""}
        open={deleteAlert || false}
        handleClose={deleteAlertClose}
      />
    </MainLayout>
  );
}
