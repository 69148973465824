import { CheckCircleOutlined, ExclamationCircleOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { openSnackbar } from "../../api/snackbar";
import { SnackbarProps } from "../../types/snackbar";

export function alertMessage(message: string, color: string, error?: any) {

  let icon;
  switch (color) {
    case "success":
      icon = <CheckCircleOutlined />;
      break;
    case "error":
      icon = <ExclamationCircleOutlined />;
      break;
    case "warning":
      icon = <WarningOutlined />;
      break;
    case "info":
      icon = <InfoCircleOutlined />;
      break;
    default:
      icon = <CheckCircleOutlined />;
      break;
  }

  if (error && error.status === 409 && typeof error.data === 'string') {
    console.log(message);
    message = error.data;
    icon = <WarningOutlined />;
    color = "warning";
  }

  openSnackbar({
    open: true,
    message: message,
    variant: "alert",
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    alert: {
      color: color,
      icon: icon,
    },
  } as SnackbarProps);

  if (error) {
    console.error(error);
  }
}