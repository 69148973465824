import React, { MouseEvent, useEffect } from "react";

// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// project-import
import IconButton from "../../components/@extended/IconButton";

import { Backdrop, Fade, Modal, Stack, Typography, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useMemo, useState } from "react";

import { CompanyType } from "../../AllTypes";
import DisplayTable from "../display-table-view";
import AddCompanyModal from "./company-helpers/AddCompanyModal";
import MainLayout from "../main-layout";
import axiosServices from "../../utils/axios";
import { CompanyEndpoints } from "../../AllLables";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { Chip } from "@mui/material";
import AlertItemDelete from "../pages-helpers/AlertItemDelete";
import { alertMessage } from "../pages-helpers/AlertMessage";
import { useParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider, FieldArray } from "formik";
import { AddCircle } from "@mui/icons-material";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { insert, updateUsingPut } from "../../api/generalRoute";
import ScrollX from "../../components/ScrollX";
import "./CompanyEdit.css";

type Params = {
  companyId: string;
};

export default function CompanyEdit() {
  const { companyId } = useParams<Params>();
  const navigate = useNavigate();

  let endpoints = CompanyEndpoints;

  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyType>();

  const [refreshData, setRefreshData] = useState<boolean>(false);
  const isEditing = companyId != "0";

  function setDataFunction() {
    if (isEditing) {
      // TODO: refactor
      axiosServices
        .get(CompanyEndpoints.getCompanyInfo(companyId || ""))
        .then((res) => {
          setCompany(res.data);
          formik.setValues(res.data);
          // setSelectedItem(res.data.items[0]);
        })
        .catch((err) => {
          alertMessage("Something went wrong ", "error");
        });
    }
  }

  // maybe change to useEffet(() => {}, [companyId])
  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    setDataFunction();
  }, [refreshData]);

  const companyInitialValues: CompanyType = {
    name: "",
    shortName: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    address: "",
    address2: "",
    isActive: true,
    locations: [],
  };

  const ItemSchema = Yup.object().shape({
    name: Yup.string().max(255).required("Name is required"),
    // shortName: Yup.string().max(255).required('Short Name is required'),
    email: Yup.string()
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i, "Email Invalid")
      .required("Email is required")
      .max(40),
    phone: Yup.string().matches(/^[0-9]{10}$/, "Phone no. must be of exactly 10 digits"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zip: Yup.string().required("Zip Code is required"),
    address: Yup.string().required("Address is required"),
  });

  const formik = useFormik({
    initialValues: companyInitialValues,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values: CompanyType, { setSubmitting }) => {
      try {
        // setSubmitting(false); return;
        // if (values.isActive === 'true') values.isActive = true;
        // if (values.isActive === 'false') values.isActive = false;

        if (isEditing) {
          updateUsingPut(endpoints, companyId, values)
            .then(() => {
              alertMessage(`Company updated successfully`, "success");
              navigate("/admin/company");
            })
            .catch((err) => {
              alertMessage("Something went wrong", "error", err);
              setSubmitting(false);
            });
        } else {
          insert(endpoints, values)
            .then((res) => {
              alertMessage(`Company added successfully`, "success");
              setSubmitting(false);
              navigate(`/admin/company/${res.data.id}`);
              toggleRefreshData();
            })
            .catch((err) => {
              alertMessage("Something went wrong", "error", err);
              setSubmitting(false);
            });
        }
      } catch (error) {
        alertMessage(`Something went wrong`, "error", error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <MainLayout>
      <Box>
        <FormikProvider value={formik}>
          {/* {({ values }) => ( */}

          <Form noValidate onSubmit={handleSubmit}>
            <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography variant="h2">{isEditing ? "Edit Company" : "Add Company"}</Typography>

              <Grid item xs={12} sx={{ pb: 2 }}>
                <Stack spacing={1} direction="row" justifyContent="flex-end">
                  <Button color="secondary" onClick={() => navigate("/admin/company")}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    Save
                  </Button>
                </Stack>
              </Grid>
            </Stack>
            <Divider />
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                        <Stack direction="row" alignItems="center">
                          {/* <InputLabel>Status :</InputLabel> */}
                          <RadioGroup {...getFieldProps("isActive")} aria-label="type" name="isActive" row>
                            <Box sx={{ fontSize: "20px", ml: 2 }}>
                              <FormControlLabel value="true" control={<Radio />} label="Active" />
                              <FormControlLabel value="false" control={<Radio />} label="Inactive" />
                            </Box>
                          </RadioGroup>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <TextField
                          fullWidth
                          id="name"
                          placeholder="Enter Name"
                          {...getFieldProps("name")}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="shortName">Short Name</InputLabel>
                        <TextField
                          fullWidth
                          id="shortName"
                          placeholder="Short Name"
                          {...getFieldProps("shortName")}
                          error={Boolean(touched.shortName && errors.shortName)}
                          helperText={touched.shortName && errors.shortName}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <TextField
                          fullWidth
                          id="email"
                          type="email"
                          placeholder="Enter Email"
                          {...getFieldProps("email")}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="phone">Phone</InputLabel>
                        <TextField
                          fullWidth
                          id="phone"
                          placeholder="Enter Phone"
                          {...getFieldProps("phone")}
                          error={Boolean(touched.phone && errors.phone)}
                          helperText={touched.phone && errors.phone}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="address">Address Line 1</InputLabel>
                        <TextField
                          fullWidth
                          id="address"
                          multiline
                          rows={2}
                          placeholder="Enter Address"
                          {...getFieldProps("address")}
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="address2">Address Line 2</InputLabel>
                        <TextField
                          fullWidth
                          id="address2"
                          multiline
                          rows={2}
                          placeholder="Enter Address (Optional)"
                          {...getFieldProps("address2")}
                          error={Boolean(touched.address2 && errors.address2)}
                          helperText={touched.address2 && errors.address2}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="city">City</InputLabel>
                        <TextField
                          fullWidth
                          id="city"
                          placeholder="Enter City"
                          {...getFieldProps("city")}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="state">State</InputLabel>
                        <TextField
                          fullWidth
                          id="state"
                          placeholder="Enter State"
                          {...getFieldProps("state")}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="country">Country</InputLabel>
                        <TextField
                          fullWidth
                          id="country"
                          placeholder="Enter Country"
                          {...getFieldProps("country")}
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="zip">Zip Code</InputLabel>
                        <TextField
                          fullWidth
                          id="zip"
                          placeholder="Enter Zip Code"
                          {...getFieldProps("zip")}
                          error={Boolean(touched.zip && errors.zip)}
                          helperText={touched.zip && errors.zip}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1}>{}</Stack>
                    </Grid>
                    {companyId !== "0" && (
                      <Grid item xs={12}>
                        <FieldArray name="locations">
                          {({ push, remove }) => (
                            <>
                              <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1, mb: 1 }}>
                                <Typography variant="h4" component="span">
                                  Locations
                                </Typography>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    push({
                                      // companyId: '',
                                      company: null,
                                      name: "",
                                      shortName: "",
                                      address: "",
                                      address2: "",
                                      phone: "",
                                      city: "",
                                      state: "",
                                      country: "",
                                      zip: "",
                                      isActive: true,
                                    })
                                  }
                                >
                                  <AddCircle />
                                </IconButton>
                              </Stack>
                              <ScrollX>
                                <Stack direction="column" className="locations">
                                  {values?.locations?.map((location, index) => (
                                    <Stack key={index} direction="row" alignItems="center" sx={{ borderTop: index > 0 ? "1px solid #f0f0f0" : null }}>
                                      <IconButton color="secondary" onClick={() => remove(index)} disabled={values?.locations?.length === 1}>
                                        <DeleteOutlined />
                                      </IconButton>
                                      {/* <Grid container item>
                                                      <Grid item sx={{ textAlign: 'right' }}>
                                                        <IconButton
                                                            color="secondary"
                                                            onClick={() => remove(index)}
                                                            disabled={values?.locations?.length === 1}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                      </Grid>
                                                    </Grid> */}
                                      <Grid container rowSpacing={2} columnSpacing={1} direction="row" sx={{ p: 1 }}>
                                        <Grid item xs={6} sm={6} md={4}>
                                          <TextField
                                            label="Location"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.name`}
                                            {...getFieldProps(`locations.${index}.name`)}
                                            // sx={{
                                            //   '& .MuiFormLabel-root': {
                                            //     pt: "4px",
                                            //   },
                                            // }}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="Short Name"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.shortName`}
                                            {...getFieldProps(`locations.${index}.shortName`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="Phone"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.phone`}
                                            {...getFieldProps(`locations.${index}.phone`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={4}>
                                          <TextField
                                            label="Address Line"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.address`}
                                            {...getFieldProps(`locations.${index}.address`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={4}>
                                          <TextField
                                            label="Address Line 2"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.address2`}
                                            {...getFieldProps(`locations.${index}.address2`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="City"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.city`}
                                            {...getFieldProps(`locations.${index}.city`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="State"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.state`}
                                            {...getFieldProps(`locations.${index}.state`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="Country"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.country`}
                                            {...getFieldProps(`locations.${index}.country`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="Zip Code"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.zip`}
                                            {...getFieldProps(`locations.${index}.zip`)}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  ))}
                                </Stack>
                              </ScrollX>
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Stack spacing={1} direction="row" justifyContent="flex-end">
                        <Button color="secondary" onClick={() => navigate("/admin/company")}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                          {companyId === "0" ? "Save & Next" : "Save"}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            {/* <Divider /> */}
            {/* <DialogActions sx={{ p: 2.5, pt: 0 }}> */}
            {/* <Grid item> */}
            {/* <Stack direction="row"> */}

            {/* </Stack> */}
            {/* </Grid> */}
            {/* </DialogActions> */}
          </Form>
          {/* )} */}
        </FormikProvider>
      </Box>
    </MainLayout>
  );
}
