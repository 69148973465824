import React from 'react';
import ThemeCustomization from './themes'
import Snackbar from './components/@extended/Snackbar'
import Locales from './components/Locales'
import HomeLayout from './pages/home-layout'
import Notistack from './components/third-party/Notistack'
import { JWTProvider } from './contexts/JWTContext'
import Providers from './components/provider.component'

function App() {
  return (
    <Providers>
    <ThemeCustomization>
      <Locales>
        <JWTProvider>
          <Notistack>
            <HomeLayout />
            <Snackbar />
          </Notistack>
        </JWTProvider>
      </Locales>
    </ThemeCustomization>
   </Providers>
    // <div className="App">

    // </div>
  );
}

export default App;
