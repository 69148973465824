import React, { MouseEvent, useEffect } from "react";

// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// project-import
import IconButton from "../../components/@extended/IconButton";

// assets
import { Backdrop, Box, Fade, Grid, Modal, Stack, Typography, Button } from "@mui/material";
import { useMemo, useState } from "react";
import { DeleteOutlined, EditOutlined, MailOutlined, PlusOutlined } from "@ant-design/icons";

import AddUserModal from "./user-helpers/AddUserModal";
import ResetPasswordModal from "./user-helpers/ResetPasswordModal";
import { UserType } from "../../AllTypes";
import DisplayTable from "../display-table-view";
import MainLayout from "../main-layout";
import axiosServices from "../../utils/axios";
import { AccountEndpoints } from "../../AllLables";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import AlertItemDelete from "../pages-helpers/AlertItemDelete";
import { alertMessage } from "../pages-helpers/AlertMessage";
import useAuth from "../../hooks/useAuth";

export default function User() {
  const [user, setUser] = useState<UserType[]>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("false");

  const emptyState = {
    companyId: null,
    firstName: "",
    lastName: "",
    // isCompanyAdmin: false,
    phoneNumber: "",
    title: "",
    address: "",
    address2: "",
    locationId: null,
    email: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    userLevel: 0,
  };
  const [selectedItem, setSelectedItem] = useState<UserType>(emptyState);
  const [itemDelete, setItemDelete] = useState<UserType>();
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState<boolean>(false);
  const deleteAlertClose = () => setDeleteAlert(false);
  const { forgotPassword } = useAuth();

  function setDataFunction() {
    axiosServices
      .get(AccountEndpoints.get)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      });
  }

  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    setDataFunction();
  }, [refreshData]);

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "70vw",
    height: "auto",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "scroll",
  };

  const columns = useMemo<ColumnDef<UserType>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        dataType: "text",
        enableSorting: false,
      },
      {
        header: "Name",
        accessorKey: "name",
        dataType: "text",
        cell: ({ row }) => row.original.firstName + " " + row.original.lastName,
      },
      {
        header: "Email",
        accessorKey: "email",
        dataType: "text",
      },
      {
        header: "Company",
        accessorKey: "companyName",
        dataType: "text",
      },
      {
        header: "Location",
        accessorKey: "locationName",
        dataType: "text",
        // cell: ({row}) => row.original.locationName && row.original.locationName?.length>0 ? row.original.locationName?.join(', ') : '-'
      },
      {
        header: "Level",
        accessorKey: "userLevelDisplayName",
        dataType: "text",
      },
      {
        header: "Actions",
        id: "actions",
        disableSortBy: true,
        // cell: EditAction,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  onClick={async (e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedItem(row.original as UserType);
                    setIsEditing(true);
                    handleOpen();
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setDeleteAlert(true);
                    setItemDelete(row.original as UserType);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reset Password">
                <IconButton
                  color="default"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setForgotPasswordEmail(row.original.email);
                    setResetPasswordModalOpen(true);
                  }}
                >
                  <MailOutlined />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Reset Password">
                <IconButton
                  color="default"
                  onClick={async (e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    try {
                      await forgotPassword(row.original.email)
                      .then(() => {
                          alertMessage(`Reset Password Mail sent to ${row.original.email}`, 'success');
                        },
                        (err) => {
                          alertMessage('Something went wrong', 'error', err)
                        })
                    } catch (error) {
                      alertMessage('Something went wrong', 'error', error);
                    }
                    
                  }}
                >
                  <MailOutlined />
                </IconButton>
              </Tooltip> */}
            </Stack>
          );
        },
        meta: {
          className: "cell-center",
        },
      },
    ],
    [forgotPassword]
  );

  function handleOpenAndEditing() {
    setSelectedItem(emptyState);
    setIsEditing(false);
    handleOpen();
  }

  function afterDelete() {
    deleteAlertClose();
    setTimeout(setDataFunction, 500);
  }

  return (
    <MainLayout>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "2%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddUserModal
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  editingData={selectedItem}
                  endpoints={AccountEndpoints}
                  toggleRefreshData={toggleRefreshData}
                  closeModal={() => setOpen(false)}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box>
      <Box sx={{ mb: 5 }}>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="h2">Users</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} alignItems="right">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAndEditing}>
                Add User
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {!user && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {user && <DisplayTable columns={columns} data={user} modalToggler={handleOpenAndEditing} showAddBtn={true} />}
      <AlertItemDelete
        endpoints={AccountEndpoints}
        deleteId={itemDelete?.id?.toString()}
        title="User"
        deleteName={itemDelete?.firstName || ""}
        open={deleteAlert}
        handleClose={afterDelete}
      />
      <ResetPasswordModal email={forgotPasswordEmail} open={resetPasswordModalOpen} handleClose={() => setResetPasswordModalOpen(false)} />
    </MainLayout>
  );
}
