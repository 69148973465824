import { configureStore } from '@reduxjs/toolkit'
// import {  applyMiddleware } from "redux";
// import userReducer from "./user/user.slice"
import { createLogger } from "redux-logger"
// import thunkMiddleware from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import rootReducer from "./root.reducer";
const loggerMiddleware = createLogger()
export const store = configureStore({
  reducer: rootReducer,
// middleware: [thunk, loggerMiddleware],
middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false,}).concat(loggerMiddleware),
//  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false,}),
// middleware:(getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware)
})
export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch