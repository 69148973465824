import { useRef, useState } from 'react';
// import { useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import CardContent from '@mui/material/CardContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// project import
import Avatar from '../../../components/@extended/Avatar';
import MainCard from '../../../components/MainCard';
import Transitions from '../../../components/@extended/Transitions';
import IconButton from '../../../components/@extended/IconButton';
// import avatar1 from '../../../../public/images/users/avatar-1.png';
import { ThemeMode } from '../../../config';

// assets
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import useAuth from '../../../hooks/useAuth';
// import { addFormulasData } from '../../../redux/formulation/formulation.slice';
import { useAppDispatch } from '../../../redux/hook';
import { useNavigate } from 'react-router-dom';
import { setUserInfo } from '../../../redux/user/user.slice';
// import { jwtDecode } from 'jwt-decode';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

export default function UserProfile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const userImg = { avatar: `/images/users/avatar-1.png`,}

  const { logout, user } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
      // dispatch(addFormulasData({ formulas: [] }));
      dispatch(setUserInfo(null));
      navigate(`/login`, {
        state: {
          from: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.400';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      {user ?
        <Tooltip title="Profile">
          <ButtonBase
            sx={{
              p: 0.25,
              bgcolor: open ? iconBackColorOpen : 'transparent',
              borderRadius: 1,
              borderBottom: '1px solid #1677ff',
              color: 'secondary.darker',
              //   border: `1px solid ${()=>'primary.main'}`,
              '&:hover': { bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'grey.400' },
              '&:focus-visible': { outline: `2px solid ${theme.palette.secondary.dark}`, outlineOffset: 2 }
            }}
            aria-label="open profile"
            ref={anchorRef}
            aria-controls={open ? 'profile-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Stack direction="row" spacing={1.25} alignItems="center" sx={{ p: 0.5 }}>
              <Avatar alt="profile user" src={userImg?.avatar} size="sm" />
              <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
                {(user.firstName !== undefined && user.lastName !== undefined) ? (user?.firstName + ' ' + user?.lastName) : 'User'}
              </Typography>
            </Stack>
          </ButtonBase>
        </Tooltip>
        :
        <Tooltip title="Logout">
          <IconButton sx={{ color: 'text.primary' }} onClick={handleLogout}>
            <LogoutOutlined />
          </IconButton>
        </Tooltip>
      }
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            {user && <Paper sx={{ boxShadow: theme.customShadows.z1, width: 290, minWidth: 240, maxWidth: { xs: 250, md: 290 } }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false}>
                  <Box>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <Avatar alt="profile user" src={userImg?.avatar} sx={{ width: 32, height: 32 }} />
                            <Stack>
                              <Typography variant="h6">{(user.firstName !== undefined && user.lastName !== undefined) ? (user?.firstName + ' ' + user?.lastName) : 'Guest'}</Typography>
                              <Typography variant="body2" color="text.secondary">
                                {user?.email !== undefined && user?.email}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Logout">
                            <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleLogout}>
                              <LogoutOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
}
